
@media (max-width:991px){
  .svg_animation {
      position: absolute;
      top: -10rem;
      z-index: -2;
      left: 0;
      mix-blend-mode: darken;
      -webkit-filter: hue-rotate(45deg);
      filter: hue-rotate(45deg);
      -webkit-transform: rotate(9.5deg);
      transform: rotate(9.5deg);
      opacity: 0.2;
  }
  .action_btn.d-flex.align-items-center.mt_60 {
    margin-top: 40px;
    margin-bottom: 0px;
}

.agency_banner_btn b {
    font-size: 15px;
    font-weight: 600;
}

  .agency_banner_btn {
    display: -webkit-flex;
    display: flex;
  }
    .s_service_section {
        margin-top: 280px;
    }
    .s_service_item{
        padding: 50px 40px 40px;
        margin-top: 30px;
    }
    .s_service_info{
        margin-top: 40px;
    }
    .s_features_section {
        margin-top: 100px;
        padding-bottom: 100px;
    }
    .s_features_item .ml_25{
        margin-left: 0;
    }
    .service_details_area .service_details_img{
        margin-left: 0;
        margin-bottom: 60px;
    }
    .service_promo_area .shape.shape_four,.service_promo_area .shape.shape_three{
        bottom: -2%;
    }
    .banner_top br,.s_service_section h2 br{
        display: none;
    }
    .footer_top .f_widget{
        padding-left: 0px;
        margin-bottom: 40px;
    }
    .footer_top{
        padding-bottom: 115px 0px 115px;
    }
    .footer_bottom,.f_social_icon_two a {
        font-size: 13px;
    }
    .slider_area{
        height: auto;
    }
    .mobile_img .mobile {
        left: 0;
        top: -143px;
    }
    .mobile_img .women_img {
        top: 70px;
        right: 280px;
    }
    .container.custom_container {
        max-width: 100%;
        padding: 0px 65px;
    }
    .price_content .price_item{
        height: 100%;
    }
    .price_content .col-sm-6{
        margin-bottom: 30px;
    }
    .pricing_area{
        padding-bottom: 80px;
    }
    .feature_info .feature_img{
        margin-left: 0;
        margin-bottom: 30px;
    }
    .feature_info .feature_img.f_img_two{
        max-width: 570px;
        position: relative;
    }
    .feature_info .feature_img.f_img_two .one{
        right: 40%;
    }
    .features_area .mt_130{
        margin-top: 80px;
    }
    .service_item {
        padding: 47px 25px 0px;
    }
    .service_item .icon {
        right: 20px;
    }
    .feature_info .feature_img.f_img_one{
        margin-left: 0px;
    }
    .service_carousel:before{
        display: none;
    }
    .protype_img{
        margin-top: 50px;
    }
    .partner_logo .p_logo_item{
        width: calc(100% / 4);
        margin-bottom: 20px;
    }
    .p_feature_item .p_feture_img_one {
        margin-right: 0;
    }
    .p_feature_item .p_feture_img_two{
        margin-left: 0;
    }
    .p_feature_item + .p_feature_item{
        margin-top: 50px;
    }
    .prototype_content{
        padding-top: 20px;
    }
    .p_service_item.pl_50,.p_service_item.pl_70{
        padding-left: 0;
    }
    .p_service_item.pr_70{
        padding-right: 0;
    }
    .p_service_item {
        margin-bottom: 40px;
    }
    .action_content{
        margin-bottom: 40px;
    }
    .action_img{
        margin-top: 0;
    }
    .p_service_info {
        margin-bottom: -40px;
    }
    .prototype_service_info {
        padding: 120px 0px;
    }
    .p_feature_item .prototype_content h2{
        padding-right: 0;
    }
    .agency_banner_area .banner_shap {
        width: auto;
        top: 0;
        max-width: 100%;
    }
    .footer_area_four .footer_top{
        padding-bottom: 40px;
    }
    .company_widget .f_subscribe{
        max-width: 300px;
    }
    .software_banner_area{
        padding-top: 150px;
    }
    .design_developers_area .col-md-5,.design_developers_area .col-md-7 .design_img{
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
    }
    .software_featured_img{
        margin-left: 0px;
        margin-bottom: 50px;
    }
    .software_service_tab_content .software_service_item{
        margin-bottom: 40px;
    }
    .software_service_tab_content{
        margin-bottom: -40px;
    }
    .software_featured_area_two .pl-0{
        padding-left: 15px !important;
    }
    .developer_product_content{
        padding-right: 0px;
        margin-bottom: 50px;
    }
    .developer_product_area{
        padding: 120px 0px;
    }
    .partner_info .logo_item{
        width: calc(100% / 3);
    }
    .saas_featured_info{
        margin-top: 60px;
    }
    .saas_featured_content.pr_70{
        margin-top: 0px;
    }
    .saas_featured_area .saas_featured_info{
        margin-top: 30px;
    }
    .saas_featured_info .f_img_one,.saas_featured_info .f_img_two{
        margin-bottom: 50px;
    }
    .saas_featured_area .saas_featured_info + .saas_featured_info{
        margin-top: 100px;
    }
    .fun_fact_content .fact_item.pl_100{
        padding-left: 10px;
    }
    .partner_logo_area_three .partner_info .logo_item {
        margin-bottom: 30px;
    }
    .footer_top_six .social-widget{
        margin-left: 0;
    }
    .footer_top_six .f_widget{
        margin-bottom: 40px;
    }
    .footer_area_six.sec_pad{
        padding-bottom: 80px;
    }
    .app_banner_area .app_banner_contentmt h2 br{
        display: none;
    }
    .app_img .mobile{
        top: -38px;
    }
    .app_banner_area {
        padding-bottom: 50px;
        padding-top: 110px;
    }
    .app_fetured_item .app_item{
        position: relative;
        display: inline-block;
    }
    .app_item.item_two {
        left: 31px;
        top: 120px;
    }
    .app_item.item_one{
        top: -50px;
    }
    .app_feature_info .app_featured_content{
        padding-bottom: 0px;
    }
    .app_featured_area_two .app_feature_info .app_featured_content{
        padding-left: 0;
        padding-bottom: 0px;
    }
    .app_testimonial_area .nav_container .owl-prev{
        left: 0;
    }
    .app_testimonial_area .nav_container .owl-next{
        right: 0;
    }
    .app_testimonial_area .nav_container .owl-prev,.app_testimonial_area .nav_container .owl-next{
        opacity: 0;
    }
    .app_testimonial_area .nav_container:hover .owl-next,.app_testimonial_area .nav_container:hover .owl-prev{
        opacity: 1;
    }
    .app_contact_info{
        left: 60px;
    }
    .app_banner_area .app_img .app_screen.one {
        top: 191px;
        left: 117px;
    }
    .app_banner_area .app_img .app_screen.two {
        top: 116px;
        left: 233px;
    }
    .app_banner_area .app_img .app_screen.three {
        top: 90px;
        left: 380px;
    }
    .get_started_area .col-lg-6.text-right img{
        margin-top: 50px;
    }
    .ex_team_item img {
        width: 100%;
    }
    .features_info.feature_info_two .agency_featured_img{
        text-align: left !important;
        margin-bottom: 50px;
    }
    .agency_banner_area_two .agency_banner_img{
        margin-bottom: 60px;
    }
    .blog-sidebar{
        margin-top: 50px;
    }
    .blog_post_item .blog_img img{
        width: 100%;
    }
    .blog_sidebar_left .blog_post{
        margin-bottom: -30px;
    }
    .blog_post .blog_post_item{
        margin-bottom: 30px;
    }
    .faq_tab{
        margin-bottom: 60px;
    }
    .faq_content h3.mt_100{
        margin-top: 50px;
    }
    .portfolio_details_info{
        padding-left: 0px;
        padding-right: 0px;
    }
    .portfolio_details_info .portfolio_category{
        margin-top: 40px;
    }
    .portfolio_details_info .portfolio_pagination{
        margin-top: 70px;
        margin-bottom: 50px;
    }
    .portfolio_details_area .portfolio_details_gallery.gallery_two{
        margin-bottom: 20px;
    }
    .portfolio_details_info .portfolio_pagination_two{
        margin-bottom: 0px;
    }
    .portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description {
        padding-left: 30px;
        padding-bottom: 28px;
    }
    .portfolio_gallery .portfolio_item .portfolio_img .hover_content .img_popup{
        right: 30px;
        top: 30px;
    }
    .agency_featured_item .agency_featured_img{
        text-align: left !important;
    }
    .agency_content{
      max-width: unset;
      width: 100%;
    }
    .agency_banner_area { padding-top: 156px; }
    .agency_featured_item .agency_featured_content{
        padding-left: 0px;
        margin-top: 50px;
    }
    .product_details_area .pr_details,.service_details_item + .service_details_item{
        margin-top: 50px;
    }
    .product_details_area .product_slider{
        padding-right: 0;
    }
    .single_product_item .single_pr_details{
        padding-top: 0px;
    }
    .shopping_cart_area .cart_table .product .media{
        width: 340px;
    }
    .coupon {
        width: 100%;
    }
    .shopping_cart_area .cart_btn{
        padding: 15px 22px;
    }
    .sign_info .login_info{
        margin-top: 50px;
    }
    .sec_title p br{
        display: none;
    }
    .menu > .nav-item + .nav-item{
        margin-left: 0px;
    }
    .menu > .nav-item{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .menu{
        padding: 10px 30px;
        background: #fff;
        margin-top: 0px;
    }
    .menu_one .navbar-collapse {
        margin-left: -20px;
        margin-right: -20px;
    }
    .navbar-collapse,.menu_four .navbar-collapse{
        overflow-y: hidden;
        margin-left: 0px;
        margin-right: 0px;
        background: #fff;
    }
    .navbar-collapse,.menu_four .navbar-collapse.show{
          max-height: 100vh;
          height: 100vh;
          overflow-y: hidden;
          margin-left: 0px;
          margin-right: 0px;
          background: #fff;
          /* overflow-y: hidden; */

    }
    .menu > .nav-item{
        margin-right: 0;
    }
    .header_area .navbar-brand,.menu_toggle{
        transition: all 0.2s linear;
    }
    .navbar .pl_100 {
        padding-left: 20px;
        padding-right: 15px;
    }
    .header_area.navbar_fixed .navbar-brand,.navbar_fixed .menu_toggle{
        margin-bottom: 0px;
    }
    .menu > .nav-item > .nav-link{
        display: inline-block;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu{
        box-shadow: none;
    }
    .menu > .nav-item.submenu.mega_menu .dropdown-menu .nav-item > .dropdown-menu{
        display: block;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item{
        padding: 0px 25px;
    }
    .menu > .nav-item.submenu .dropdown-menu{
        box-shadow: none;
        padding: 0px;
        margin: 0px;
    }
    .burger_menu{
        display: none;
    }
    .menu > .nav-item.submenu.mega_menu .mega_menu_inner{
        box-shadow: none;
        position: static;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item{
        padding-left: 15px;
        padding-right: 15px;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item:first-child{
        padding-top: 0;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item:last-child{
        padding-bottom: 0;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item .nav-link:before{
        content: "\e649";
        display: inline-block;
        font-family: 'themify';
        margin-right: 8px;
        width: auto;
        font-size: 9px;
        float: none;
    }
    .menu > .nav-item.submenu.mega_menu > .dropdown-menu.show{
        margin-top: 10px;
        box-shadow: none;
        min-height: 350px;
    }
    .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item + .nav-item{
        padding-top: 0px;
    }
    .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item .dropdown-menu,.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu{
        padding-left: 15px;
        opacity: 1;
        visibility: visible;
        min-height: -webkit-max-content;
        min-height: -moz-max-content;
        min-height: max-content;
        display: block !important;
    }
    .menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item{
        padding: 0px 25px;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item:first-child ,.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item:first-child{
        padding-top: 12px;
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item:last-child,.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item:last-child{
        padding-bottom: 0px;
    }
    .menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item > .dropdown-menu > .nav-item:last-child{
        padding-bottom: 20px;
    }
    .menu_one .mobile_btn{
        margin-left: 15px;
        display: none;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item{
        width: 100%;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu .nav-item{
        width: calc(100% / 3);
        padding: 15px 20px;
    }
    .menu_one .btn_get {
        margin-left: 29px;
    }
    .menu_four .btn_get{
        border-color: #ccc5fa;
        box-shadow: none;
        margin-left: 20px;
    }
    .header_area .menu_six .cus_dark {
        margin-left: 30px;
        border-color: #3d64f4;
        color: #3d64f4;
        display: inline-block;
        margin-bottom: 15px;
    }
    .header_area a.menu_cus, .header_area .btn_get.ml-0 {
      margin-left: 0px !important;
      margin-top: 1rem;
      font-size: 1rem;
      border: 1px solid #fff;
      background: #fbfbfb;
      color: #000;
    }
    .header_area .menu_five .menu_custfive {
        border-color: #3d64f4;
        color: #3d64f4;
        display: inline-block;
    }
    .header_area .menu_four .menu_custfive {
        border-color: #563bd1;
        color: #563bd1;
        display: inline-block;
    }
    .menu_nine .btn_get{
        display: inline-block;
        border-color: #5f51fb;
        color: #5f51fb;
        margin-left: 28px;
        margin-bottom: 10px;
    }
    .header_area_three .menu_four .menu_toggle .hamburger span{
        background: #6754e2;
    }
    .header_area_three{
        padding: 28px 0px;
    }
    .header_area_six .container,.header_area_seven .container{
        position: relative;
    }
    .footer_top_two .company_widget{
        padding-right: 0px;
    }
    .footer_top_two .f_widget{
        margin-bottom: 40px;
    }
    .footer_area_two .footer_top_two .row{
        margin-bottom: -40px;
    }
    .header_area .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .menu_one,.header_area.navbar_fixed .menu_one,.menu_two,.menu_six,.menu_seven{
        padding: 15px 0px;
    }
    .w_menu .nav-item .nav-link{
        color: #051441;
    }
    .w_menu .nav-item:hover .nav-link, .w_menu .nav-item.active .nav-link{
        color: #00aff0;
    }
    .prototype_banner_area{
        padding-top: 140px;
    }
    .menu_four .menu > .nav-item .nav-link,.menu_five .menu > .nav-item .nav-link,.menu_six .menu > .nav-item .nav-link,.menu_seven .menu .nav-item .nav-link{
        color: #051441;
    }
    .menu_five .menu > .nav-item:hover .nav-link,.menu_five .menu > .nav-item.active .nav-link{
        color: #3d64f4;
    }
    .menu_five .menu > .nav-item:hover .nav-link:before,.menu_five .menu > .nav-item.active .nav-link:before{
        background: #3d64f4;
    }
    .menu_seven .menu > .nav-item:hover .nav-link,.menu_seven .menu > .nav-item.active .nav-link{
        color: #4069eb;
    }
    .menu_seven .menu > .nav-item:hover .nav-link:before,.menu_seven .menu > .nav-item.active .nav-link:before{
        background: #4069eb;
    }
    .menu_four .menu > .nav-item:hover .nav-link,.menu_four .menu > .nav-item.active .nav-link{
        color: #6754e2;
    }
    .menu_four .menu > .nav-item:hover .nav-link:before,.menu_four .menu > .nav-item.active .nav-link:before{
        background: #6754e2;
    }
    .payment_features_content.pl_70{
        padding-left: 0;
    }
    .animation_img_two{
        display: none;
    }
    .payment_banner_content {
        max-width: 90%;
    }
    .payment_service_area .service-content{
        margin-bottom: 40px;
    }
    .payment_clients_area .payment_features_content{
        padding-right: 0px;
    }
    .payment_clients_area .payment_clients_inner{
        height: 450px;
        margin-top: 50px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.eight{
        left: 500px;
    }
    .payment_clients_area{
        padding-top: 0px;
    }
    .payment_testimonial_area{
        padding-top: 50px;
    }
    .payment_testimonial_info .testimonial_content{
        padding-bottom: 50px;
    }
    .footer_nine_top .company_widget.pr_100{
        padding-right: 0px;
    }
    .footer_nine_top .pl_100{
        padding-left: 15px;
    }
    .footer_nine_top .f_widget{
        margin-bottom: 40px;
    }
    .footer_nine_top{
        padding-bottom: 80px;
    }
    .payment_action_area {
        padding: 125px 0px 120px;
    }
    .payment_features_area{
        padding-bottom: 0px;
    }
    .payment_features_area_four{
        padding-bottom: 100px;
    }
    .saas_map_area img{
        max-width: 100%;
    }
    .saas_banner_area_two .animation_img img{
        width: 100%;
    }
    .saas_banner_area_two .animation_img{
        padding: 0px 15px;
    }
    .saas_banner_area_two .shap_img{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        bottom: 176px;
    }
    .section_container .intro {
        height: 50%;
    }
    .saas_banner_area_two .shap_img{
        bottom: 0;
    }
    .saas_features_area_two {
        padding-top: 200px;
    }
    .saas_service_img{
        margin-bottom: 40px;
    }
    .saas_service_item + .saas_service_item {
        margin-top: 80px;
    }
    .saas_subscribe_area .saas_action_content{
        padding: 0;
        text-align: center;
    }
    .saas_subscribe_area .justify-content-end{
        -webkit-justify-content: center !important;
                justify-content: center !important;
        margin-top: 20px;
    }
    .saas_banner_area_two .animation_img{
        position: absolute;
        bottom: -150px;
    }
    .saas_banner_content p br{
        display: none;
    }
    .saas_banner_content h2{
        line-height: 48px;
    }
    .saas_banner_area{
        padding-top: 150px;
    }
    .prototype_content .btn_three{
        margin-top: 25px;
    }
    .app_service_area {
        padding-top: 100px;
    }
    .app_testimonial_area{
        padding-top: 150px;
        padding-bottom: 100px;
    }
    .app_screenshot_area,.experts_team_area {
        padding: 100px 0px;
    }
    .payment_features_content.pr_70{
        padding-right: 0;
    }
    .job_listing .listing_tab .list_item figure a, .job_listing .listing_tab .list_item figure img{
        width: auto;
        max-width: 95%;
    }
    .job_listing .listing_tab .list_item figure{
        width: 60px;
        margin-right: 10px;
    }
    .job_listing .listing_tab .list_item .joblisting_text{
        padding-left: 15px;
    }
    .job_listing .listing_tab .list_item .joblisting_text .jobsearch-table-cell{
        width: 70%;
    }
    .job_listing .listing_tab .list_item{
        padding: 20px;
    }
    .job_listing .listing_tab .list_item .joblisting_text ul li{
        font-size: 12px;
        padding: 0px 5px;
    }
    .job_listing {
        margin-top: 100px;
    }
    .job_details_area .pl_70,.job_apply_area .pl_70{
        padding-left: 15px;
        margin-bottom: 50px;
    }
    .checkout_area .cart_total_box{
        margin-left: 0px;
        padding: 50px 20px;
    }
    .login_area .login_info{
        padding-right: 0;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .login_area .login_info:before{
        right: -20%;
    }
    .login_img{
        margin: 0px auto;
        padding: 70px 0px;
    }
    .footer_bottom .f_menu li + li {
        margin-left: 7px;
    }
    .shopping_cart_area .cart_table .total,.shopping_cart_area .cart_table .quantity{
        width: 95px;
    }
    .shopping_cart_area .cart_table .del-item{
        width: 60px;
    }
    .shopping_cart_area .cart_table .del-item .total{
        margin-right: 10px;
    }
    .triangle_shape {
        height: 115%;
        transform: rotate(-22deg);
        -webkit-transform: rotate(-22deg);
        width: 170%;
    }
    .new_service .separator{
        display: none;
    }
    .new_service .new_service_content{
        margin-top: 0;
    }
    .payment_footer_area_two{
        padding-bottom: 60px;
    }

    .startup_tab .nav-item {
        width: calc(100% / 3);
        margin-bottom: 25px;
    }
    .new_startup_banner_area{
        min-height: auto;
        padding: 150px 0px 100px;
    }
    .startup_tab{
        margin-left: -15px;
        margin-right: -15px;
    }
    .new_startup_banner_area .new_startup_img{
        padding-right: 0;
        padding-bottom: 50px;
    }
    .stratup_service_img .shape{
        left: 0;
    }
    .progress_bar_area .progress_item{
        padding: 0px 15px;
    }
    .testimonial_slider_four {
        padding: 0px 125px;
    }
    .stratup_testimonial_info{
        margin-top: 50px;
    }
    .video_content {
        padding: 0px 60px;
    }
    .video_info{
        height: 350px;
        max-width: 100%;
    }
    .video_leaf{
        left: 0;
        top: -22px;
    }
    .cup{
        right: -27px;
    }
    .get_started_three .get_btn_info .get_btn_item h3 {
        font-size: 18px;
        line-height: 32px;
    }
    .get_started_three .get_btn_info .get_btn_item{
        padding-right: 0;
        margin-bottom: 20px;
    }
    .intregration_content{
        padding-top: 50px;
    }
    .digital_video_slider {
        width: 45%;
    }
    .saas_banner_area_three .slider_item .image_mockup{
        width: 780px;
        padding: 0px 40px;
    }
    .saas_banner_area_three .slider_item .image_mockup .phone{
        max-width: 13%;
        right: 25px;
    }
    .saas_banner_area_three .slider_item .image_mockup .laptop{
        max-width: 90%;
    }
    .saas_banner_area_three .slider_item .slidet_content h2 {
        font-size: 36px;
    }
    .saas_banner_area_three .slider_item .slidet_content{
        padding-top: 140px;
    }
    .saas_banner_area_three .slider_item{
        min-height: 670px;
    }
    .saas_banner_area_three .slider_item .slidet_content_two{
        padding-top: 160px;
    }
    .service_promo_area .shape.shape_two{
        left: 0;
    }
    .perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_three{
        padding-left: 15px;
        padding-right: 15px;
    }
    .service_details_area .details_content{
        padding-top: 50px;
    }
    .study_details .details_info,.study_details_two .study_details_content,.study_details_three .study_details_content{
        padding-top: 50px;
    }
    .breadcrumb_area{
        padding-top: 180px;
    }
    .details_img.text-right{
        text-align: left !important;
    }
    .download_content .download_btn {
        width: 200px;
        height: 200px;
        margin-bottom: 30px;
    }
    .download_content .download_btn .pulse-x{
        width: 70px;
        height: 70px;
    }
    .download_content h2 br{
        display: none;
    }
    .thanks_content a{
        margin-top: 30px;
    }
    .thanks_content h2 {
        font-size: 36px;
        margin-bottom: 22px;
        margin-top: 16px;
    }
    .saasland_container{
        height: 100%;
        min-height: inherit;
    }
    .saasland_col_screen, .saasland_container .saasland_col_content{
        width: 100%;
        height: 100vh;
    }
    .saasland_container .saasland_col_content .container_wrap{
        min-height: 517px;
        padding: 0px 15px;
    }
    .saasland_col_screen{
        height: 70vh;
    }
    .saasland_col_screen .saasland_bg{
        display: none;
    }
    .saasland_col_screen .clock{
        text-align: left;
        padding-left: 12px;
    }
    .saasland_col_screen .saasland_social{
        left: 15px;
    }
    .saasland_container .saasland_col_content .saasland_content{
        width: 94%;
    }
    .saasland_container .saasland_col_content .saasland_content, .saasland_container .saasland_col_content .saasland_bottom,.saasland_container .saasland_col_content .saasland_log{
        left: 15px;
    }
    .error_content_two p {
        font-size: 15px;
        line-height: 25px;
    }
    .error_content_two img {
        max-width: 93%;
    }
    .error_content_two .search {
        margin: 30px 0px;
    }
    .pr_sidebar{
        margin-top: 60px;
    }
    .alter_nav{
        position: absolute;
        right: 63px;
        top: -4px;
    }
    .search_cart.menu{
        padding: 0px;
        margin-top: 0px;
        -webkit-flex-direction: initial;
                flex-direction: initial;
    }
    .header_area_six + section {
        margin-top: 122px;
    }
    .header_contact_info li{
        font-size: 13px;
    }
    .header_contact_info li:before{
        margin: 0px 5px 0px 0px;
    }
    .navbar .search_cart .search a.nav-link:before{
        display: none;
    }
    .software_promo_area .round_shape {
        max-width: 690px;
        min-height: 690px;
    }
    .payment_features_item{
        margin-bottom: 30px;
    }
    .feedback_area_two .sec_title,.feedback_area_three .sec_title{
        padding-right: 156px;
    }
    .payment_subscribe_info{
        display: block;
    }
    .payment_subscribe_info .subscribe-form{
        -webkit-justify-content: center;
                justify-content: center;
        margin-top: 20px;
        margin-left: 0;
    }
    .payment_subscribe_info .payment_subscribe_content{
        text-align: center;
    }
    .payment_price_item {
        padding: 50px 10px 55px;
    }
    .payment_price_item.center, .payment_price_item:hover{
        padding: 80px 10px 86px;
    }
    .feedback_area_three {
        height: 640px;
    }
    .seo_features_img .round_circle {
        right: 0;
    }
    .seo_features_img.seo_features_img_two .round_circle {
        left: 0;
    }
    .seo_features_img.seo_features_img_two img{
        margin-left: 0;
    }
    .seo_features_img{
        margin-bottom: 60px;
    }
    .seo_subscribe_form{
        max-width: 100%;
        margin-left: -9px;
        margin-right: -9px;
    }
    .studies_item .text{
        padding-left: 20px;
        padding-right: 20px;
    }
    .studies_item .text h4 {
        font-size: 18px;
    }
    .seo_fact_info .seo_fact_item{
        width: 145px;
        height: 145px;
    }
    .seo_fact_info .seo_fact_item .counter{
        font-size: 40px;
        margin-bottom: 4px;
    }
    .seo_fact_info:before{
        display: none;
    }
    .seo_fact_info .seo_fact_item:nth-child(even){
        margin-top: 135px;
    }
    .seo_sec_title h2 br{
        display: none;
    }
    .seo_call_action_text h2 {
        font-size: 30px;
        line-height: 44px;
    }
    .seo_call_to_action_area{
        padding: 80px 0px;
    }
    .new_footer_top .pl_70{
        padding-left: 0;
    }
    .new_footer_top .f_widget{
        margin-bottom: 50px;
    }
    .header_area_six .alter_nav{
        top: 0px;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu{
        display: block !important;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu .nav-item{
        padding-left: 10px;
        padding-right: 10px;
    }
    .header_area .menu_seven a.Cus_seven {
        color: #3d64f4;
        margin-left: 30px;
        display: inline-block;
        margin-bottom: 20px;
    }
    .domain_form_inner input{
        width: 50%;
    }
    .domain_form_inner .domain_select{
        width: 23%;
    }
    .domain_form_inner button{
        width: 27%;
    }
    .hosting_menu.menu_four .btn_get {
        color: #333f64;
        line-height: 42px;
        margin-left: 30px;
    }
    .h_features_content{
        margin-top: 40px;
    }
    .h_action_content h2 {
        font-size: 31px;
        line-height: 42px;
    }
    .h_price_body .price_head,.h_price_body .h_p_list{
        padding-right: 0;
    }
    .h_price_body .h_p_list .h_price_item.c_width,.h_price_body .price_head .p_head.c_width{
        width: 25%;
    }
    .h_price_body .h_p_list .h_price_item,.h_price_body .price_head .p_head{
        width: 15%;
    }
    .h_map ul li:nth-child(2){
        left: 80px;
    }
    .h_map ul li:nth-child(6) {
        left: 150px;
    }
    .h_blog_item .h_blog_content {
        padding: 25px 20px;
    }
    .h_action_promo_area .hosting_btn{
        padding: 16px 25px;
    }
    .h_footer_dark .about-widget.pl_70,.h_footer_dark .about-widget.pl_20{
        padding-left: 0;
    }
    .h_footer_dark .f_widget{
        margin-top: 30px;
    }
    .h_footer_dark{
        padding: 70px 0px 100px;
    }

    .menu_pos .w_menu .nav-item:hover .nav-link, .w_menu .nav-item.active .nav-link{
        color: #5e53fb;
    }
    .navbar_fixed .menu_pos .container .menu_toggle .hamburger span,.navbar_fixed .menu_pos .container .menu_toggle .hamburger-cross span{
        background: #051441;
    }
    .ticket_item{
        width: calc(94% / 3);
    }
    .ticket_item + .ticket_item {
        margin-left: 3%;
    }
    .ticket_item h2 {
        font-size: 35px;
    }
    .hosting_title h2 br{
        display: none;
    }
    .pos_developer_product_area .developer_product_content{
        margin-bottom: 0;
        margin-top: 40px;
    }
    .pos_features_img {
        max-width: 500px;
        padding-right: 40px;
    }
    .pos_features_img .shape_img{
        right: 0;
    }
    .pos_features_img.img_left{
        padding-right: 0;
        padding-left: 40px;
    }
    .pos_features_img.img_left .shape_img{
        left: 0;
    }
    .pos_features_content{
        padding-left: 0;
    }
    .pos_about_list{
        -webkit-column-count: 2;
                column-count: 2;
    }
    .pos_subscribe .btn_pos {
        padding: 17px 26px;
    }
    .pos_about_img{
        max-width: 100%;
    }
    .pos_blog_item{
        margin-bottom: 30px;
    }
    .chat_info{
        margin-top: 40px;
    }
    .pos_footer_area{
        padding-bottom: 0;
    }
    .flex-row-reverse .pos_features_content{
        padding-right: 0;
    }
    .erp_service_item.pl_70{
        padding-left: 0;
    }
    .erp_service_item.pr_70{
        padding-right: 0;
    }
    .erp_action_area img{
        max-width: 100%;
    }
    .erp_features_img_two{
        margin-bottom: 40px;
        max-width: 550px;
    }
    .erp_content_two{
        padding-left: 0;
    }
    .erp_item_features + .erp_item_features {
        margin-top: 100px;
    }
    .erp_analytics_item{
        padding: 0px;
    }
    .erp_analytics_item p {
        font-size: 19px;
    }
    .flex-row-reverse .erp_features_img_two .img_icon {
        left: auto;
        right: -55px;
    }
    .animation_inner li:nth-child(2) {
        left: 107px;
    }
    .animation_inner li:nth-child(4) {
        left: 293px;
    }
    .animation_inner li:nth-child(7) {
        left: 452px;
    }
    .animation_inner li:nth-child(12) {
        left: 78%;
    }
    .animation_inner li:nth-child(9) {
        left: 450px;
    }
    .animation_inner li:nth-child(10) {
        left: 607px;
    }
    .animation_inner li:nth-child(6) {
        top: 36px;
    }
    .animation_inner li:nth-child(17) {
        left: 632px;
    }

    .agency_service_area {
      padding: 0px 0px 0;
      text-align: center;
    }

    .startup_fuatures_area {
      p {
        text-align: center;
        font-size: 16px;
      }
    }

    .agency_featured_area h2 strong {
      font-size: 2.2rem;
    }

    .agency_featured_item .img_ftr {
      width: 555px;
    }

    .agency_featured_content {
      .description_agency {
        text-align: center;
      }
    }

    .parallax_second { opacity: 1 !important; }
    .saas_subscribe_area {

      h4 {
        font-size: 1.8rem;
      }

    }

    .f_widget.dark_widget.company_widget.wow.fadeInUp {
      text-align: center;
    }

    .dark_f_bottom {
      p {
        font-size: 11px;
      }
      span {
        display: block;
        padding-left: 0;
        padding-bottom: unset;
      }
    }

    .breadcrumb_content {
      h1 {
        font-size: 3rem;
      }
      p {
        font-size: 18px;
      }
    }

    .breadcrumb_content h1 {
      font-size: 2.5rem;
    }

    .sec_pad, .prototype_service_info {
      text-align: center;
    }

    .job_info .info_item i { display: none; }
    .job_details_area.sec_pad {
      padding: 90px 20px;
    }

    .job_info .info_item { padding-left: 0; }

    .breadcrumb_area {
      padding: 120px 0px 120px;
      &:before {
        width: 100%;
        height: 190%;
        margin-top: -8rem;
       }

    }

    .service_details ul {
      text-align: left;
    }

    .service_details_header p {
      padding: 0rem 50px;
    }

    .double_item {
      .service_details ul li {
          display: inline-block;
          padding-left: 20px;
          padding-right: 20px;
      }
    }

    .explore_app.explore_app_left {
      display: none !important;
    }

    .explore_app.explore_app_mobile {
      display: -webkit-flex !important;
      display: flex !important;
    }

    .agency_banner_btn {
        padding: 10px 20px;
    }

    #burger {
        display: block;
    }

    /*Modal-Box*/
    .resposive_menu_wrapper {
        &.view_menu {
          display: block !important;
        }
    }

    .image_req_wrapper img {
        display: none;
    }

    .footer_demo_btn {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
    }

    .footer_bottom, .f_social_icon_two a {
      font-size: 30px;
    }

    .f_widget.about-widget .f_list li {
      display: block;
      padding-right: 0;
    }

    .footer_menu {
      padding-top: 0;
      text-align: center;

    }

    .footer_top .f_widget {
    padding-left: 0px;
    margin-bottom: 40px;
    text-align: center;
    }

    .footer_menu {
      float: unset;
    }

    .f_widget.dark_widget.about-widget {
      padding-top: 0;
    }

    .testimonial_title {
        text-align: center;
        padding: 2rem 2rem 1rem;
    }

    .footer_menu {
    padding-right: 0;
  }


}
