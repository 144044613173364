@media (min-width:2000px){
    .call_action_area .action_one{
        left: 40px;
    }
    .erp_banner_area_two .animation_img{
        margin-top: -320px;
    }

    .breadcrumb_content {
      p {
        font-size: 18px;
      }
    }

}
