
@media(max-width:1650px){
    .about_content{
        padding: 100px 30px;
    }
    .saas_banner_area_two .shap_img{
        bottom: 150px;
    }
    .section_container{
        height: 795px;
    }
    .section_container .intro_content p {
        margin-bottom: 40px;
    }
    .saas_features_item{
        padding: 0px;
    }
    .stratup_app_screen img{
        max-width: 100%;
    }
    .stratup_app_screen .phone{
        max-width: 80%;
        bottom: -290px;
        left: -80px;
    }
    .saasland_col_screen .saasland_bg {
        -webkit-clip-path: polygon(0px 100%, 0px 0px, 95% 0px, 0% 100%);
        clip-path: polygon(0px 100%, 0px 0px, 34% 0px, 0% 100%);
    }
    .saasland_container .saasland_col_content .saasland_content h1 {
        font-size: 40px;
        margin-bottom: 55px;
    }
}
