
@media (max-width:650px){
    .about_img .about_img_slider .about_item.w45,.about_img .about_img_slider .about_item.w55{
        width: 100%;
    }
    .about_img .pluse_icon{
        display: none;
    }
    .app_img .mobile {
        top: -83px;
    }
    .comment-box .post_comment .reply-comment {
        padding-left: 25px;
    }
    .portfolio_filter .work_portfolio_item + .work_portfolio_item {
        margin-left: 15px;
    }
    .sign_info .login_info .sign-in-form .d-flex{
        display: block !important;
    }
    .sign_info .lead-text{
        padding-right: 0px;
        padding-top: 15px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.two,.payment_clients_area .payment_clients_inner .clients_item.one,.payment_clients_area .payment_clients_inner .clients_item.three{
        left: 0;
    }
    .payment_clients_area .payment_clients_inner .clients_item.four {
        left: 205px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.seven {
        left: 150px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.eight {
        left: 250px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.six {
        left: 215px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.five {
        left: 330px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.four {
        left: 0;
        top: 540px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.eight {
        left: 165px;
        top: 105px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.six{
        top: 225px;
        left: 190px;
    }
    .payment_testimonial_info .testimonial_content {
        padding-left: 110px;
        margin-top: 20px;
    }
    .payment_testimonial_info .testimonial_content .icon{
        left: 0;
    }
    .payment_clients_area .payment_clients_inner {
        height: 690px;
    }
    .agency_featured_area {
        padding-top: 80px;
    }
    .about_img .about_img_slider .about_item .about_text{
        bottom: 30px;
    }
    .software_banner_content h2 {
        font-size: 35px;
    }
    .t_color3 br{
        display: none;
    }
    .design_developers_area .btn_four{
        margin-top: 35px;
    }
    .company_widget .mt_40{
        margin-top: 25px;
    }
    .f_widget h3.mb-30{
        margin-bottom: 15px;
    }
    .footer_nine_top .f_widget .f-title:after{
        margin-top: 10px;
    }
    .app_featured_content h2{
        margin-bottom: 15px;
    }
    .app_featured_content .learn_btn_two{
        margin-top: 10px;
    }
    .f_widget .widget-wrap{
        margin-top: 20px;
    }
    .agency_banner_area_two .agency_content_two h2{
        font-size: 28px;
        line-height: 35px;
    }
    .agency_banner_area_two .agency_banner_img{
        max-width: 70%;
        margin-bottom: 30px;
    }
    .agency_banner_area_two {
        padding-top: 100px;
        padding-bottom: 80px;
    }
    .error_contain p br{
        display: none;
    }
    .error_contain h2{
        letter-spacing: 0.50px;
    }
    .app_img .dot_three{
        top: 48%;
    }
    .app_img .dot_two{
        top: 69%;
    }
    .triangle_shape{
        -webkit-transform: rotate(65deg);
        transform: rotate(65deg);
    }
    .perfect_solution_area .perfect_solution_left .per_solution_content h2 {
        font-size: 32px;
        line-height: 44px;
    }
    .testimonial_slider_four {
        padding: 0px 75px;
    }
    .stratup_app_screen .phone {
        bottom: -140px;
        left: -77px;
    }
    .stratup_app_screen .laptop {
        right: -42px;
        bottom: -92px;
    }
    .post_author_two{
        padding: 25px 20px;
    }
    .comment_inner .comment_box .post_comment{
        padding: 30px 20px 30px;
    }
    .menu > .nav-item.submenu.mega_menu > .dropdown-menu.show {
        padding: 0px 10px;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu > .nav-item{
         padding: 15px 10px;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu > .nav-item .item .text{
        font-size: 12px;
    }
    .feedback_area_two{
        height: auto;
        padding-bottom: 50px;
    }
    .payment_price_item{
        width: 100%;
        margin-bottom: 30px;
    }
    .payment_price_item.center, .payment_price_item:hover{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    .payment_priceing_area .payment_price_info{
        padding-bottom: 0px;
    }
    .payment_subscribe_info .subscribe-form .form-control {
        width: 300px;
    }
    .seo_call_action_text{
        display: block;
        text-align: center;
    }
    .seo_call_action_text h2{
        margin-bottom: 20px;
    }
    .seo_partner_logo_area .seo_sec_title{
        margin-bottom: 0px;
    }
    .seo_sec_title p br{
        display: none;
    }
    .seo_fact_info{
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .seo_fact_info .seo_fact_item:first-child{
        margin-top: 0px;
    }
    .seo_fact_info .seo_fact_item:nth-child(even){
        margin-top: 8px;
    }
    .seo_sec_title h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu .nav-item{
        width: 33.33%;
    }
    .intro{
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }
    .border_shap{
        left: 0;
        width: 350px;
    }
/*    new css*/
    .domain_form_inner .domain_select {
        width: 17%;
    }
    .domain_form_inner .domain_select .selectpickers{
        text-align: left !important;
        padding-left: 12px;
    }
    .domain_form_inner .domain_select .selectpickers:after{
        right: 15px;
    }
    .domain_form_inner button {
        width: 33%;
    }
    .domain_price li + li {
        margin-left: 20px;
    }
    .hosting_title p br{
        display: none;
    }
    .h_features_item{
        padding-right: 0;
    }
    .h_price_body .h_p_list .h_price_item{
        position: relative;
    }
    .h_price_body .h_p_list .h_price_item:before{
        content: attr(data-title);
        position: absolute;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        font-weight: 500;
        text-transform: uppercase;
    }
    .h_price_body .h_p_list{
        display: block;
        padding: 0px;
        margin-bottom: 10px;
    }
    .h_price_body .h_p_list:last-child{
        margin-bottom: 0;
    }
    .h_price_body .h_p_list:last-child .h_price_item.c_width{
        border-bottom: 0px;
    }
    .h_price_body .h_p_list .h_price_item{
        display: block;
        width: 100%;
        overflow: hidden;
        padding: 10px 0px;
        border-bottom: 1px solid #f7f0f0;
    }
    .h_price_body .h_p_list .h_price_item.c_width, .h_price_body .price_head .p_head.c_width{
        width: 100%;
    }
    .h_price_body .h_p_list .h_price_item h5{
        width: 50%;
        display: block;
        float: right;
    }
    .h_price_body .price_head{
        display: none;
    }
    .h_map ul li:nth-child(2) {
        left: 60px;
    }
    .pos_footer_area .leaf_left, .pos_footer_area .leaf_right {
        max-width: 20%;
    }
    .pos_action_area .pos_action_content h2 {
        font-size: 35px;
    }
    .pos_footer_bottom img{
        max-width: 40%;
    }
    .pos_action_area .pos_action_content p {
        font-size: 18px;
        line-height: 30px;
    }

    .animation_inner li:nth-child(20),.animation_inner li:nth-child(12){
        display: none;
    }

    .agency_featured_item .img_ftr {
      width: 440px;
    }

    .agency_featured_item {
      margin-bottom: 0px;
    }

}
