
@media (max-width: 1550px){
    .s_features_item .s_features_img img{
        max-width: 100%;
    }
    .service_promo_area .shape.shape_two{
        left: -40%;
    }
    .s_features_item .ml_50{
        margin-left: 0;
    }
    .s_features_item .s_features_content{
        padding-right: 0px;
        padding-left: 45px;
    }
    .saas_home_img img,.software_img img{
        max-width: 100%;
    }
/*
    .s_promo_info .promo_item.item_five{
        left: 80%;
        top: 53%;
    }
*/
    // .container.custom_container{
    //     max-width: 1170px;
    // }
    .service_item{
        padding: 47px 15px 0px;
    }
    .call_action_area .action_one {
        left: -720px;
    }
    .company_widget img,.protype_img{
        max-width: 100%;
    }
    .p_feature_item .p_feture_img_one {
         margin-right: -20px;
    }
    .p_feature_item .p_feture_img_two {
         margin-left: -20px;
    }
    .action_img {
        margin-right: 0;
    }
    .service_carousel {
        padding-left: 70px;
    }
    .agency_featured_img img{
        max-width: 100%;
    }
    .about_content_left {
        padding: 0px 0px 0px 50px;
    }
    .about_content {
        padding: 60px 30px 100px;
    }
    .about_img .about_img_slider .about_item .about_text{
        padding: 0px 20px;
    }
    .agency_banner_area .banner_shap{
        width: 80%;
        top: -100px;
    }
    .p_service_item.agency_service_item{
        padding-right: 0px;
        padding-left: 0;
    }
    .saas_featured_info .f_img_two{
        margin-right: 0px;
    }
    .saas_featured_info .f_img_one{
        margin-left: 0px;
    }
    .footer_top_six .social-widget .f_social_icon a + a{
        margin-left: 0px;
    }
    .app_img .mobile{
        margin-left: -100px;
    }
    .menu > .nav-item:nth-last-child(-n+2).submenu .dropdown-menu{
        left: auto;
        right: 0;
    }
    .split_slider_content{
        padding: 40px 0px 48px 45px;
    }
    .app_featured_content {
        padding: 50px 0px 30px 0px;
    }
    .split_title {
        font-size: 34px;
        line-height: 42px;
    }
    .split_banner .app_img .phone_img{
        max-width: 67%;
    }
    .app_img .dot_one {
        right: 160px;
    }
    .app_img .text_bg.two {
        right: -4%;
    }
    .app_img .text_bg.one {
        left: -63%;
    }
    .app_img .text_bg.three {
        right: -28%;
    }
    .app_img .dot_three {
        top: 57%;
        left: 60px;
    }
    .app_img .dot_two {
        right: 156px;
        top: 51%;
    }
    .section_4 .split_banner .app_img{
        margin-left: 0;
    }
    .split_content .contact_form_box .text_box {
        margin-bottom: 7px;
    }

    .explore_app.explore_app_left {
      margin-left: -140px;
    }

}
