
@media (max-height:420px){
    .navbar-collapse {
        max-height: 310px;
    }
    .payment_banner_area{
        min-height: 435px;
    }
    .agency_content h2 {
      font-size: 27px;
      line-height: 42px;
    }

      .agency_testimonial_info .testimonial_slider {
        max-width: 70% !important;
      }

    

}
