

@media (max-width:1199px){

  .startup_tab {
    border: 0px;
    margin-bottom: 10px;

    .nav-item .nav-link h3 { font-size: 16px; }

  }

  .header_area.navbar_fixed .navbar-brand img {
      width: 185px;
  }

  .agency_banner_btn {
    font-size: 16px;
    padding: 15px 20px;
  }

    .s_service_item{
        padding: 50px 22px 40px;
    }
    .saas_home_area{
        height: 920px;
    }
    .s_pricing-item{
        padding: 55px 25px;
    }
    .software_promo_area .round_shape{
        border: 0px;
    }
    .feature_info .f_content h2{
        padding-right: 0px;
    }
    .mobile_img .mobile{
        left: -180px;
    }
    .container.custom_container {
        max-width: 960px;
    }
    .service_item .icon{
        right: 5px;
    }
    .slider_area .slider_content p br{
        display: none;
    }
    .footer_top_two .f_width_24{
        width: 35%;
    }
    .f_width_12{
        width: 15%;
    }
    .f_width_20{
        width: 25%;
    }
    .f_width_24,.f_width_12,.f_width_20{
        margin-bottom: 35px;
    }
    .footer_top_two .pl-30,.footer_top_two .pl_40,.tab-content .tab_img{
        padding-left: 0;
    }
    .service_carousel .owl-stage{
        left: 0;
    }
    .prototype_content h2{
        padding-right: 0px;
    }
    .action_area_two {
        padding-bottom: 40px;
        padding-top: 20px;
    }
    .agency_featured_item .agency_featured_content{
        padding-right: 0px;
        padding-left: 20px;
    }
    .features_info .dot_img,.dot{
        display: none;
    }
    .agency_featured_item {
        margin-top: 80px;
        margin-bottom: 0px;
    }
    .features_info{
        padding-bottom: 0px;
    }
    .about_content_left,.about_img{
        max-width: 100%;
        -webkit-flex: 1;
                flex: 1;
        padding-left: 0;
    }
    .agency_about_area{
        display: block !important;
        padding: 0px 15px;
    }
    .about_content{
        bottom: 0px;
        margin-bottom: 0px;
        padding: 90px 30px 100px;
    }
    .agency_content h2 {
        font-size: 36px;
        line-height: 53px;
        margin-bottom: 20px !important;
    }

    .agency_banner_area .protype_img {
      margin-top: 0rem;
    }

    .agency_banner_area .banner_shap{
        width: 100%;
        top: -200px;
    }
    .agency_about_area .owl-dots {
        left: 30px;
        top: -63px;
        margin-top: 0px;
        bottom: auto;
    }
    .software_banner_content h2{
        font-size: 40px;
    }
    .software_service_tab_content .software_service_item{
        padding-right: 0px;
    }
    .developer_product_content .develor_tab{
        padding-left: 0px;
        padding-right: 0px;
    }
    .saas_shap{
        max-width: 100%;
    }
    .saas_featured_content.pr_70,.developer_product_content{
        padding-right: 0px;
    }
    .saas_featured_info .saas_featured_item{
        padding: 50px 10px 50px 20px;
    }
    .saas_featured_content h2 br{
        display: none;
    }
    .saas_featured_info .pl_100{
        padding-left: 15px;
    }
    .saas_featured_info .pr_70{
        padding-right: 15px;
    }
    .footer_top_six .social-widget {
        margin-left: -42px;
    }
    .app_banner_area .app_img .app_screen{
        max-width: 20%;
    }
    .app_banner_area .app_img .app_screen.one {
        top: 108px;
        left: 6px;
    }
    .app_banner_area .app_img .app_screen.two{
        top: 70px;
        left: 89px;
    }
    .app_banner_area .app_img .app_screen.three {
        top: 30px;
        left: 171px;
    }
    .app_featured_content {
        padding: 50px 0px 90px 0;
    }
    .app_item.item_two{
        left: -10px;
        top: 70px;
    }
    .app_fetured_item{
        margin-left: 0px;
    }
    .app_item.item_three{
        bottom: 35px;
    }
    .app_feature_info .app_img .dot{
        display: block;
    }
    .app_img .text_bg.one {
        left: -26%;
    }
    .app_img .dot_three {
        left: 164px;
    }
    .agency_banner_area_two .agency_content_two h2{
        font-size: 32px;
        line-height: 45px;
    }
    .agency_banner_area_two{
        padding-top: 150px;
    }
    .features_info.feature_info_two{
        padding-bottom: 0;
    }
    .blog_content{
        padding: 25px 15px 42px 20px;
    }
    .blog-sidebar {
        padding-left: 0px;
    }
    .blog-sidebar .widget.widget_recent_post .post_item .media-body{
        padding-left: 12px;
    }
    .menu > .nav-item + .nav-item {
        margin-left: 20px;
    }
    .blog_post_item .blog_content {
        padding: 22px 12px 35px;
    }
    .blog_single .blog_content{
        padding-bottom: 0px;
    }
    .faq_area .pr_50{
        padding-right: 15px;
    }
    .portfolio_details_info.pl_100{
        padding-left: 0;
        padding-right: 0;
    }
    .p_details_three .portfolio_pagination {
        margin-top: 155px;
    }
    .process_area .features_info{
        padding-bottom: 0px;
    }
    .service_details_area .service_details_img img{
        max-width: 100%;
    }
    .cart_box{
        width: 100%;
    }
    .sign_info .login_info{
        padding-left: 0px;
    }
    .sign_info .social_tag li a{
        margin-left: 0;
    }
    .login-form .extra{
        font-size: 13px;
    }
    .feature_info .feature_img.f_img_two .four{
        left: 0;
    }
    .payment_features_area .payment_featured_img,.payment_features_area .payment_featured_img.img_two{
        margin-left: 0px;
        margin-right: 0px;
    }
    .payment_features_area .payment_featured_img img{
        max-width: 100%;
    }
    .payment_service_item{
        padding-right: 0;
    }
    .animation_img_two {
        bottom: 20px;
    }
    .footer_nine_top .pl_100{
        padding-left: 50px;
    }
    .footer_nine_top {
        padding-bottom: 100px;
        padding-top: 125px;
    }
    .saas_service_content{
        padding-right: 0px;
        padding-left: 0px;
    }
    .saas_service_item + .saas_service_item {
        margin-top: 118px;
    }
    .p_feature_item .prototype_content h2{
        padding-right: 0px;
    }
    .app_featured_area {
        padding: 90px 0px;
    }
    .app_feature_info + .app_feature_info {
        margin-top: 175px;
    }
    .job_details_area .pl_70,.job_apply_area .pl_70{
        padding-left: 20px;
    }
    .login_img {
        padding-left: 20px;
        margin-right: -115px;
    }
    .login_area .login_info {
        padding-right: 50px;
    }
    .triangle_shape{
        height: 107%;
    }
    .app_featured_area_two .app_feature_info {
        margin-top: 120px;
    }
    .new_service .new_service_content{
        padding: 50px 15px;
    }
    .perfect_solution_area{
        display: block;
    }
    .perfect_solution_area .perfect_solution_right,.perfect_solution_area .perfect_solution_left{
        max-width: 100%;
        height: 500px;
        display: block;
        overflow: hidden;
    }
    .perfect_solution_area .perfect_solution_left{
        display: -webkit-flex !important;
        display: flex !important;
        padding: 100px 0px;
        height: auto;
    }
    .perfect_solution_area .perfect_solution_right .bg_img{
        position: relative;
    }
    .perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_two,.perfect_solution_area .perfect_solution_left .per_solution_content,.perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_three{
        max-width: 960px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0 auto;
    }
    .startup_tab_img .web_img img{
        max-width: 100%;
    }
    .startup_tab_img .phone_img img{
        max-width: 80%;
    }
    .menu_four .btn_get_radious{
        margin-left: 5px;
    }
    .new_startup_banner_area .new_startup_img img{
        max-width: 100%;
    }
    .new_startup_content h2{
        font-size: 35px;
        line-height: 45px;
    }
    .new_startup_banner_area .new_startup_img{
        padding-right: 20px;
    }
    .stratup_service_img img{
        max-width: 100%;
    }
    .stratup_service_info{
        margin-left: 0;
        padding-top: 55px;
    }
    .testimonial_slider_four {
        padding: 0px 25px;
    }
    .testimonial_slider_four .item p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 19px;
    }
    .stratup_testimonial_info {
        height: 465px;
    }
    .startup_content_three h2{
        font-size: 35px;
        line-height: 45px;
    }
    .navbar .pl_100 {
        padding-left: 48px;
    }
    .stratup_app_screen .laptop {
        right: -115px;
        bottom: -165px;
    }
    .stratup_app_screen .phone{
        bottom: -250px;
        left: -108px;
    }
    .get_started_three .get_btn_info .get_btn_item{
        padding-right: 20px;
    }
    .intregration_logo {
        padding-right: 0;
    }
    .intregration_icon img{
        max-width: 100%;
    }
    .digital_content h2{
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 16px;
    }
    .saas_banner_area_three .owl-dots{
        right: 10px;
    }
    .payment_features_area_four{
        padding-bottom: 100px;
    }
    .case_study_item .text{
        padding: 20px 30px;
    }
    .details_img img{
        max-width: 100%;
    }
    .saasland_container .saasland_col_content .saasland_content h1 br{
        display: none;
    }
    .saasland_col_screen,.saasland_container .saasland_col_content{
        width: 50%;
    }
    .saasland_col_screen .clock .timer{
        width: 100px;
        padding: 20px 0px;
        text-align: center;
    }
    .saasland_col_screen .saasland_bg{
        -webkit-clip-path: polygon(0px 100%, 0px 0px, 5% 0px, 0% 100%);
        clip-path: polygon(0px 100%, 0px 0px, 5% 0px, 0% 100%);
    }
    .saasland_container .saasland_col_content .saasland_log {
        left: 4%;
        top: 70px;
    }
    .saasland_container .saasland_col_content .saasland_content,.saasland_container .saasland_col_content .saasland_bottom{
        left: 4%;
    }
    .saasland_col_screen{
        display: -webkit-flex;
        display: flex;
    }
    .error_content_two{
        max-width: 960px;
        margin: 0 auto;
    }
    .error_content_two img{
        max-width: 60%;
    }
    .error_content_two h2 {
        font-size: 32px;
        line-height: 44px;
        margin-top: 35px;
    }
    .price_info_two .price_item:first-child {
        padding-left: 10px;
    }
    .navbar .search_cart .shpping-cart{
        margin-left: 0;
    }
    #multiscroll {
        overflow: auto;
        height: 100vh;
        transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
    }
    #multiscroll .ms-left, #multiscroll .ms-right {
        width: 100% !important;
        position: relative !important;
        top: auto !important;
    }
    .menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu{
        max-width: 960px;
    }
    .payment_subscribe_info .subscribe-form .form-control {
        width: 360px;
    }
    .payment_subscribe_info{
        padding-left: 20px;
        padding-right: 20px;
    }
    .payment_subscribe_info .subscribe-form .btn_four{
        padding: 16px 32px;
    }
    .payment_features_img img{
        max-width: 100%;
        margin-left: 0;
    }
    .payment_features_content_two h2 br{
        display: none;
    }
    .payment_features_content_two h2 {
        font-size: 37px;
        line-height: 48px;
    }
    .payment_features_img:before{
        left: 0;
    }
    .seo_features_content .seo_features_item{
        padding-right: 0;
    }
    .seo_features_img .round_circle{
        right: -100px;
    }
    .seo_features_img.seo_features_img_two .round_circle{
        left: -60px;
    }
    .seo_features_img img{
        max-width: 100%;
    }
    .seo_fact_info .seo_fact_item.last {
        margin-top: 66px;
    }
    .spliet_slider_img .phone_one,.spliet_slider_img .phone_two{
        max-width: 50%;
    }
    .spliet_slider_img .phone_one{
        top: 0;
    }
    .spliet_slider_img .phone_two{
        top: 70px;
    }
    .section_4 .split_banner .app_img img,.app_img .text_bg{
        visibility: visible !important;
    }
    .section_4 .split_banner .app_img img{
        max-width: 100%;
    }
    .border_shap.two {
        left: 2%;
    }
    .ms-section.section_3{
        background-image: linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
    }

    .menu > .nav-item > .nav-link { font-size: 13px !important; }
    .header_area .navbar-brand img {
        width: 185px;
    }
    #navbarSupportedContent .pl_120 {
      padding-left: 30px;
    }
/*    new css*/
    .hosting_content h2{
        font-size: 40px;
        line-height: 50px;
    }
    .hosting_service_item {
        padding: 48px 20px;
    }
    .h_features_img img{
        max-width: 100%;
    }
    .h_features_content {
        padding-right: 0;
    }
    .b_line li:nth-child(8) {
        right: 95px;
    }
    .b_line li:nth-child(7){
        right: 200px;
    }
    .b_line li:nth-child(5){
        right: 395px;
    }
    .b_line li:nth-child(4){
        right: 250px;
    }
    .h_price_inner{
        max-width: 100%;
    }
    .h_map ul li .round .dot{
        display: block;
    }
    .ticket_item {
        padding: 30px 25px 70px;
    }
    .developer_product_content .develor_tab .nav-item{
        margin-left: 20px;
    }
    .pos_developer_product_area .tab_img_info .tab_img{
        margin-left: 0;
    }
    .pos_features_img .shap_img img{
        max-width: 100%;
    }
    .pos_features_img{
        padding: 40px 0px;
    }
    .pos_about_list li br{
        display: none;
    }
    .pos_about_list li{
        padding-left: 30px;
    }
    .pos_subscribe .btn_pos{
        padding: 17px 16px;
    }
    .pos_blog_item .h_blog_content{
        padding-left: 20px;
        padding-right: 20px;
    }
    .chat_info{
        padding: 77px 20px;
    }
    .chat_info h3 {
        font-size: 22px;
    }
    .chat_info img{
        margin-right: 15px;
    }
    .pos_footer_area .pl_20,.pos_footer_area .pl_70{
        padding-left: 0;
    }
    .pos_features_area .pos_features_img{
        padding: 30px 30px 30px 0px;
    }
    .pos_features_img .shape_img{
        right: 0;
    }
    .pos_features_img .shape_img{
        width: calc(100% - 30px);
    }
    .pos_features_area .pos_features_img.img_left{
        padding-right: 0;
        padding-left: 30px;
    }
    .pos_features_img.img_left .shape_img{
        left: 0;
        right: auto;
    }
    .pos_item + .pos_item {
        margin-top: 80px;
    }
    .animation_inner li:nth-child(1){
        left: 0;
    }
    .animation_inner li:nth-child(2) {
        top: 35px;
    }
    .animation_inner li:nth-child(8) {
        top: 40%;
        left: 5px;
    }
    .animation_inner li:nth-child(13) {
        left: 0px;
    }
    .animation_inner li:nth-child(3) {
        top: 115px;
        left: 175px;
    }
    .animation_inner li:nth-child(4) {
        left: 326px;
    }
    .animation_inner li:nth-child(5) {
        top: 153px;
        left: 330px;
    }
    .animation_inner li:nth-child(9) {
        top: 175px;
        left: 470px;
    }
    .animation_inner li:nth-child(6) {
        top: 15px;
        left: 673px;
    }
    .animation_inner li:nth-child(7) {
        left: 520px;
    }
    .animation_inner li:nth-child(10) {
        left: 625px;
    }
    .animation_inner li:nth-child(17) {
        left: 605px;
    }
    .animation_inner li:nth-child(18) {
        bottom: -30px;
        left: 690px;
    }
    .animation_inner li:nth-child(12) {
        top: 50px;
        left: 85%;
    }
    .animation_inner li:nth-child(19) {
        bottom: 21px;
        left: 864px;
    }
    .animation_inner li:nth-child(20) {

        bottom: 65px;
        right: 74px;
    }
    .animation_inner li:nth-child(11) {
        top: 128px;
        left: 860px;
    }

    .agency_service_area {
      padding: 0px 0px 150px;
    }

    .features_btn {
      padding-right: 0;
      display: none;
      a {
        margin-top: unset;
        box-shadow: unset;
        display: block;
        border-radius: 0;
        padding: 15px;
        background: #0d0d0d;
        border-radius: 3rem;
      }
    }

    .service_details_header p {
      font-size: 21px;
      padding: 0rem 125px;
    }

    .service_details {
      h2 {
        font-size: 23px;
        margin-bottom: 10px;
      }
    }

    .service_details_header h2 {
      margin-bottom: 20px;
    }

    .service_details ul li {
      font-size: 16px;
    }

    .explore_app.explore_app_left {
        margin-left: 0;
    }

    .p_service_item strong {
      font: 600 21px/19px "Poppins", sans-serif;

      &:before{
        top: 20px;
        border-bottom: 4px solid;
      }
    }

    .agency_banner_area {
      padding-top: 170px;
      padding-bottom: 95px;
    }

    .protype_img {
      display: none;
    }

    // .agency_content{
    //   .protype_img {
    //     &.protype_img_responsive {
    //       display: block;
    //     }
    //   }
    // }

    .swiper-slide img {
        width: 150px;
    }

    .f_widget.about-widget .f_list li a {
      font: 300 15px/15px "Poppins", sans-serif;
    }

    .dark_widget .f-title {
      font-size: 16px;
    }

    .f_social_icon_two a {
      font-size: 20px;
    }

    .footer_menu {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .swiper-container.swiper-testimonials {
      width: 100%;
      height: 130px;
    }

    .footer-right {
      display: unset;
      display: unset;
      -webkit-justify-content: unset;
      justify-content: unset;
    }

    .p__item strong:before {
      top: 18px;
    }

}
