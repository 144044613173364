

@media (max-width:1450px){
    .app_banner_area{
        background-size: cover;
        padding-bottom: 100px;
        padding-top: 150px;
    }
    .app_img .mobile {
        margin-top: 140px;
        max-width: 100%;
        position: relative;
        top: -110px;
        z-index: -1;
        margin-left: 0px;
    }
    .app_banner_contentmt p br{
        display: none;
    }
    .app_feature_info .col-lg-5{
        text-align: center;
    }
    .app_feature_info + .app_feature_info .app_featured_content{
        padding: 50px 0px 90px 126px;
    }
    .app_screenshot_area .app_screenshot_slider .item {
        padding: 10px 10px 20px;
    }
    .agency_banner_img{
        max-width: 100%;
        margin-left: 0px;
    }
    .developer_product_area .service_tab_img{
        max-width: 100%;
    }
    .s_service_item{
        padding: 50px 20px 40px;
    }
    .payment_features_area .payment_featured_img{
        margin-left: -130px;
    }
    .payment_testimonial_area .testimonial_img {
        margin-bottom: -38px;
    }
    .payment_clients_area .clients_bg_shape_right{
        right: -77.7%;
    }
    .payment_clients_area .payment_clients_inner .clients_item.three{
        left: 150px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.four{
        left: 400px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.six{
        left: 295px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.five{
        left: 520px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.seven{
        left: 400px;
    }
    .perfect_solution_area .perfect_solution_left .per_solution_content {
        max-width: 580px;
        padding-right: 60px;
    }
    .perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_two{
        padding: 0px 0px 0px 60px;
    }
    .spliet_slider_img img{
        max-width: 67%;
    }
    .split_slider_content h2{
        font-size: 45px;
        line-height: 58px;
    }
    .split_content .contact_form_box {
        padding-top: 8px;
    }
    .text_box textarea {
        height: 140px;
    }
    .ms-left .ms-section.section_2 .split_banner .app_img {
        margin-top: 50px;
    }
    .feedback_area_two .sec_title{
        padding-left: 15px;
    }
    .feedback_slider_two .owl-nav{
        right: 15px;
    }
    .erp_banner_area_two .animation_img {
        margin-top: -279px;
    }
}
