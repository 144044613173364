
@media (max-width:400px){
    .header_area_six .header_top{
        display: none;
    }
    .header_area_six + section {
        margin-top: 68px;
    }
    .payment_content_two .payment_img .payment_icon.icon_one,.payment_content_two .payment_img .payment_icon.icon_two,.payment_content_two .payment_img .payment_icon.icon_three,.payment_content_two .payment_img .payment_icon.icon_four,.payment_content_two .payment_img .payment_icon.icon_five,.payment_content_two .payment_img .payment_icon.icon_six{
        display: none;
    }
    .agency_banner_area .protype_img {
      // display: none;
    }

    .container.custom_container {
      padding: 0px 15px;
    }

    .p_service_item strong {
      &:before{
        top: 20px;
        border-bottom: 4px solid;
      }
    }

  .swiper-features.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-features .swiper-pagination-custom, .swiper-features .swiper-pagination-fraction {
    bottom: 35px !important;
}

.agency_featured_area h2 {
    font-size: 1.5rem;
}

.agency_featured_area h2 strong {
  font-size: 1.5rem;
  letter-spacing: 6px;
}

.saas_subscribe_area h4 {
    font-size: 1.3rem;
}

.f_widget.about-widget .f_list li {
    margin-bottom: 5px;
}

.responsive_menu_info {
  .widget-wrap {
    margin-top: 10px;
  }
    p {
      font-size: 13px;
      line-height: 1.7;

        span {
          font-size: 13px;
        }
        a {
          font-size: 13px;
        }

      }
  }


}
