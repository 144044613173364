@media (max-width:450px){
    .price_tab{
        max-width: 100%;
        border-radius: 3px;
    }
    .price_tab .nav-item .nav-link{
        display: block;
        position: relative;
    }
    .price_tab,.price_tab .nav-item{
        display: block;
    }
    .price_tab .nav-item .nav-link.active{
        background: #00aff0;
        border-radius: 3px;
    }
    .partner_info .logo_item{
        width: 100%;
    }
    .app_img .phone_img{
        max-width: 55%;
    }
    .app_img .text_bg{
        max-width: 60%;
    }
    .app_img .text_bg.three {
        right: 0%;
        bottom: 125px;
    }
    .app_img .text_bg.two {
        right: 12%;
        top: -37px;
    }
    .app_img .text_bg.one{
        left: -2%;
    }
    .app_img .dot_three {
        left: 128px;
    }
    .app_img .dot_one {
        right: 145px;
        top: 20px;
    }
    .price_tab.price_tab_two .nav-item .nav-link.active{
        background: #5f2eed;
    }
    .get_content .app_btn{
        margin-top: 5px;
    }
    .pr_details .ratting{
        float: none;
        text-align: left;
        padding-top: 12px;
    }
    .pr_footer,.login-form .extra{
        display: block !important;
    }
    .pr_details .post-body .ratting{
        padding-top: 0px;
    }
    .product_info_details .comment-box .comment-content {
        padding-left: 80px;
    }
    .product_info_details .pr_tab{
        display: block;
        border-radius: 0px;
    }
    .product_info_details .pr_tab .nav-item .nav-link{
        border-radius: 0px;
    }
    .product_info_details .pr_tab .nav-item{
        float: none;
    }
    .sign_info {
        padding: 30px 15px;
    }
    .text_box input[type="text"], .text_box textarea, .text_box input[type="password"]{
        padding-left: 10px;
    }
    .payment_testimonial_info .testimonial_content .icon{
        top: -53px;
    }
    .payment_testimonial_info .testimonial_content{
        padding-left: 0;
    }
    .mobile_img .women_img{
        right: 80px;
    }
    .mobile_img .mobile{
        top: -35px;
    }
    .post_share a + a {
        margin-left: 0px;
    }
    .coupon .button{
        position: relative;
        right: 0;
        top: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        margin-top: 15px;
    }
    .feedback_item .feed_back_author{
        display: block;
    }
    .feedback_item .ratting{
        text-align: left;
        padding-left: 80px;
    }
    .feedback_slider_two .feedback_item{
        padding: 45px 20px 25px;
    }
    .partner_logo_area_five h2 br{
        display: none;
    }
    .company_banner_area .company_banner_content h2 {
        font-size: 35px;
        line-height: 46px;
    }
    .testimonial_slider_four .item p{
        color: #677294;
    }
    .testimonial_slider_four .item h5,.testimonial_slider_four .item h6{
        color: #051441;
    }
    .stratup_testimonial_info:before{
        display: none;
    }
    .stratup_testimonial_info{
        height: auto;
        margin: 50px 0px;
    }
    .stratup_app_screen{
        display: none;
    }
    .startup_content_three .btn_six{
        margin-bottom: 15px;
    }
    .btn_six + .btn_six{
        margin-left: 0;
    }
    .startup_content_three h2 {
        font-size: 28px;
        margin-bottom: 15px;
        line-height: 42px;
    }
    .startup_content_three p{
        margin-bottom: 30px;
    }
    .intregration_logo{
        display: block;
    }
    .intregration_logo .intregration_item{
        max-width: 100%;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu .nav-item{
        width: 250px;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu > .nav-item .item .text {
        font-size: 16px;
    }
    .seo_partner_logo_area{
        padding-top: 0;
    }
    .split_slider_content .br_shap{
        width: 290px;
    }
    .split_slider_content {
        padding: 40px 0px 48px 17px;
    }
    .hosting_tab .nav-item{
        width: 100%;
    }
    .h_price_tab p{
        padding: 30px;
    }

    .agency_featured_area h2 {
        font-size: 1.8rem;
    }

    .agency_featured_area h2 strong {
      font-size: 1.7rem;
    }

    .service_details_item {
      padding: 15px;
    }

    .service_details_header p {
      padding: 0;
    }




}
