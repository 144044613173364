@media (max-width:480px){
    .feedback_area_three{
        height: auto;
    }
    .startup_tab_img .phone_img img {
        max-width: 40%;
    }
    .testimonial_slider_four {
        padding: 0px 15px;
    }
    .testimonial_slider_four .item p {
        font-size: 14px;
        line-height: 26px;
    }
    .testimonial_slider_four .item .author_img{
        margin-bottom: 5px;
    }
    .stratup_testimonial_info {
        height: 385px;
    }
    .saas_banner_area_three .slider_item .slidet_content .slider_btn + .slider_btn{
        margin-left: 0;
    }
    .post_author_two{
        display: block;
    }
    .comment_inner .comment_box .post_comment .reply_comment{
        margin-left: 0;
    }
    .post_author_two .img_rounded{
        margin-right: 0;
        margin-bottom: 20px;
    }
    .header_area .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .feedback_area_two .sec_title,.feedback_area_three .sec_title{
        padding-right: 0;
    }
    .feedback_slider_two .owl-nav{
        display: none;
    }
    .seo_features_img .round_circle.two{
        top: -30px;
    }
    .seo_features_content .seo_features_item .icon{
        margin-right: 10px;
    }
    .seo_features_img.seo_features_img_two .round_circle{
        width: 250px;
        height: 250px;
    }
    .seo_features_img.seo_features_img_two .round_circle.two{
        width: 300px;
        height: 300px;
    }
    .pos_banner_text h2 {
        font-size: 30px;
        line-height: 38px;
    }
    .chat_info {
        display: block;
    }
    .chat_info img{
        margin-right: 0;
        padding-bottom: 35px;
    }
    .pos_about_list{
        -webkit-column-count: 1;
                column-count: 1;
    }

    .swiper-features {

      .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
                    background-size: 20px !important;
      }

      .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
                background-size: 20px !important;
      }

      .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
                background-size: 20px !important;
      }

      .swiper-features.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-features .swiper-pagination-custom, .swiper-features .swiper-pagination-fraction {
          bottom: 35px !important;
      }
    }

  .startup_fuatures_area {
    .sec_title.mb_70 {
      margin-bottom: 35px;
    }
  }

  .agency_testimonial_info .testimonial_slider .testimonial_item p {
    font-size: 13px;
  }

  .agency_testimonial_info .testimonial_slider .owl-dots {
    display: none;
  }

  .agency_testimonial_info .testimonial_slider .testimonial_item .author_img img {
    display: none;
  }

  .agency_testimonial_info .testimonial_slider {
    padding: 10px 20px;
  }




}
