
@media (max-width:576px){
    .app_featured_area{
        padding-top: 135px;
    }
    .app_banner_area{
        padding-bottom: 10px;
    }
    .saas_home_area {
        height: auto;
    }
    .saas_home_img{
        margin-top: 50px;
    }
    .s_features_item .s_features_content{
        padding-left: 0px;
    }
    .footer_bottom .f_social_icon_two,.footer_bottom .f_menu,.footer_area_two .footer_bottom p{
        text-align: center !important;
    }
    .footer_bottom .f_social_icon_two{
        margin-bottom: 6px;
    }
    .banner_top h2{
        font-size: 26px;
        line-height: 35px;
    }
    .l_height60,.l_height50 {
        line-height: 45px;
    }
    .f_size_30{
        font-size: 24px;
        line-height: 35px;
    }
    .mobile_img .mobile {
        left: 0;
        top: -100px;
        max-width: 100%;
    }
    .saas_banner_area_two .shap_img{
        height: 280px;
    }
    .call_action_area .action_one {
        left: -878px;
    }
    .f_size_40{
        font-size: 30px;
    }
    .footer_area_two .footer_bottom p{
        margin-bottom: 8px !important;
    }
    .partner_logo .p_logo_item{
        width: 100%;
        margin-bottom: 30px;
    }
    // .agency_content h2{
    //     font-size: 28px;
    //     line-height: 45px;
    //     margin-bottom: 23px;
    // }
    .agency_content .action_btn{
        display: block !important;
        text-align: center;
    }
    .agency_content .action_btn .agency_banner_btn{
        display: block;
        margin-bottom: 20px;
    }
    .agency_banner_btn_two{
        margin-left: 0px;
    }
    .about_content{
        padding: 90px 15px 100px;
    }
    .developer_product_content .develor_tab .nav-item{
        margin-left: 25px;
    }
    .partner_info .logo_item{
        width: calc(100% / 2);
    }
    .fun_fact_content .fact_item h1{
        font-size: 60px;
    }
    .feedback_slider .feedback_item .feed_back_author{
        display: block;
    }
    .feedback_slider .feedback_item .ratting{
        text-align: left;
        padding-left: 75px;
    }
    .app_screenshot_area .app_screenshot_slider .item{
        padding-left: 30px;
        padding-right: 30px;
    }
    .app_img .phone_img{
        max-width: 80%;
    }
    .app_banner_contentmt h2 {
        line-height: 60px;
        font-size: 40px;
    }
    .app_banner_area .app_img .app_screen.one {
        top: 155px;
        left: 15px;
    }
    .app_banner_area .app_img .app_screen.two {
        top: 123px;
        left: 105px;
    }
    .app_banner_area .app_img .app_screen.three {
        top: 90px;
        left: 194px;
    }
    .app_item.item_one {
        top: -143px;
    }
    .app_item.item_two {
        left: 31px;
        top: -60px;
    }
    .app_item.item_three {
        bottom: 50px;
    }
    .app_item.item_four{
        right: -52px;
        bottom: 36px;
    }
    .get_started_area .col-lg-6.text-right img{
        max-width: 100%;
    }
    .get_content .app_btn.app_btn_two{
        margin-left: 0px;
    }
    .app_service_item{
        padding: 50px 20px;
    }
    .app_contact_info .info_item{
        padding-left: 38px;
        margin-bottom: 26px;
    }
    .app_contact_info{
        left: 20px;
        padding: 45px 15px 15px;
    }
    .app_screenshot_area .app_screenshot_slider .owl-prev {
        left: calc(50% - 32px);
    }
    .app_screenshot_area .app_screenshot_slider .owl-next {
        right: calc(50% - 80px);
    }
    .partner_logo_area_two .subscribe_form_info {
        padding: 50px 20px;
    }
    .blog_grid_item{
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }
    .comment-box .post_comment .reply-comment{
        padding-left: 0;
    }
    .comment-box .post_comment .post_author .media-left {
        margin-right: 10px;
    }
    .breadcrumb_content h1{
        font-size: 24px;
        line-height: 32px;
    }
    .portfolio_filter{
        display: block;
        text-align: center;
        margin-bottom: 30px;
    }
    .portfolio_filter .work_portfolio_item{
        margin-bottom: 5px;
    }
    .portfolio_gallery .portfolio_item .portfolio_img img,.single_product_item .product_img img{
        width: 100%;
    }
    .shopping_cart_area .cart_btn.cart_btn_two{
        margin-left: 0;
    }
    .shopping_cart_area .cart_btn{
        margin-bottom: 10px;
    }
    .login-form .extra{
        display: block !important;
    }
    .s_promo_info .promo_item{
        width: 90px;
        height: 90px;
    }
    .s_promo_info .promo_item.item_four{
        top: 17%;
        left: 69%;
    }
    .s_promo_info .promo_item.item_three {
        left: 0px;
        top: 25px;
    }
    .s_promo_info .promo_item.item_two{
        left: 0;
        top: 146px;
    }
    .s_promo_info .promo_item.item_seven {
        top: 66%;
        left: 64%;
    }
    .software_promo_area .round_shape .r_shape.r_shape_five{
        width: 115px;
        height: 115px;
    }
    .software_promo_area .round_shape .r_shape.r_shape_five img{
        max-width: 100%;
    }
    .s_promo_info .promo_item.item_six {
        top: 57%;
        left: 0%;
    }
    .s_promo_info .promo_item.item_nine{
        left: 18px;
        top: 83%;
    }
    .s_promo_info .promo_item.item_five{
        left: 72%;
        top: 44%;
    }
    .feature_info .feature_img.f_img_one .one{
        left: 0;
        max-width: 80%;
    }
    .feature_info .feature_img.f_img_one .three{
        max-width: 69%;
    }
    .feature_info .feature_img.f_img_two .one {
        right: 131px;
        max-width: 43%;
    }
    .mobile_btn{
        display: none;
    }
    .action_area_three .action_content .white_btn{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .payment_clients_area .payment_clients_inner .clients_item.five {
        left: 180px;
        top: 517px;
    }
    .footer_nine_bottom{
        text-align: center;
    }
    .footer_nine_bottom .flag_selector{
        float: none;
        margin-top: 12px;
    }
    .payment_action_content h2{
        line-height: 40px;
    }
    .payment_testimonial_area .testimonial_img img{
        max-width: 100%;
    }
    .payment_testimonial_area .testimonial_img {
        margin-bottom: -13px;
    }
    .payment_banner_content .action_btn{
        display: block !important;
    }
    .payment_banner_content .agency_banner_btn_two{
        display: block;
        margin-left: 0px;
        margin-top: 15px;
    }
    .payment_banner_content h1 {
        line-height: 38px;
        font-size: 28px;
    }
    .pay_btn.pay_btn_two{
        margin-left: 0px;
    }
    .section_container .intro_content h1 {
        font-size: 30px;
        line-height: 45px;
    }
    .section_container .intro_content .subcribes{
        display: block;
    }
    .section_container .intro_content .subcribes .form-control{
        width: 100%;
    }
    .section_container .intro_content .subcribes .btn_submit{
        position: relative;
        margin-top: 10px;
        display: block;
        max-width: 100%;
        width: 100%;
    }
    .saas_banner_area_two .animation_img {
        bottom: -40px;
    }
    .saas_features_area_two {
        padding-top: 100px;
    }
    .software_banner_content .action_btn{
        display: block !important;
    }
    .video_btn{
        display: block;
        margin-left: 10px;
        margin-top: 20px;
    }
    .saas_banner_content p.mt_30{
        margin-top: 20px;
    }
    .app_testimonial_area .nav_container .owl-prev, .app_testimonial_area .nav_container .owl-next{
        display: none;
    }
    .app_feature_info .app_featured_content .app_btn{
        margin-top: 15px;
    }
    .banner_top .subcribes .btn_submit{
        position: relative;
        right: 0;
        display: block;
        top: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        margin: 20px auto 0px;
    }
    .sec_title h2.mb_20{
        margin-bottom: 10px;
    }
    .breadcrumb_area {
        padding: 110px 0px 50px;
    }
    .shop_grid_area .row,.shop_list_area .row{
        text-align: center;
    }
    .shop_menu_right {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }
    .shop_list_area .shop_page_number{
        margin-top: 40px;
    }
    .product_details_area .pr_details, .service_details_item + .service_details_item {
        margin-top: 30px;
    }
    .blog_content .post_date{
        top: -60px;
    }
    .banner_top .subcribes{
        display: block;
    }
    .banner_top .subcribes .form-control{
        display: block;
        width: 100%;
    }
    .s_subcribes .form-control{
        line-height: 70px;
        height: 70px;
    }
    .s_subcribes .btn-submit{
        font-size: 18px;
        line-height: 70px;
    }
    .app_featured_area_two .app_feature_info {
        margin-top: 30px;
    }
    .app_img .dot_two {
        top: 65%;
    }
    .app_img .dot_three{
        top: 41%;
    }
    .app_img .dot_two {
        right: 115px;
    }
    .perfect_solution_area .perfect_solution_left .per_solution_content .btn_six{
        margin-left: 0;
    }
    .startup_tab .nav-item{
        width: calc(100% / 2);
    }
    .startup_tab_img .phone_img{
        text-align: right;
    }
    .stratup_service_img .phone_img{
        width: 20%;
    }
    .video_content{
        padding: 0px;
    }
    .digital_video_slider .owl-stage{
        padding-left: inherit !important;
    }
    .saas_banner_area_three .slider_item .image_mockup{
        display: none;
    }
    .saas_banner_area_three .owl-dots{
        display: none;
    }
    .saas_banner_area_three .slider_item{
        padding-bottom: 100px;
    }
    .saas_subscribe_area_two .subscribe_form_info {
        padding: 80px 20px;
    }
    .case_study_item img{
        width: 100%;
    }
    .download_content h2 {
        font-size: 30px;
    }
     .saasland_col_screen{
        height: 100vh;
    }
    .saasland_col_screen .clock .timer{
        margin-bottom: 15px;
    }
    .saasland_container .saasland_col_content .saasland_content {
        width: 90%;
    }
    .saasland_container .saasland_col_content .saasland_content h1 {
        font-size: 33px;
        margin-bottom: 50px;
    }
    .saasland_container .saasland_col_content .saasland_content h2 {
        font-size: 28px;
    }
    .saasland_container .saasland_col_content .saasland_bottom{
        width: 95%;
    }
    .pr_sidebar .widget{
        text-align: left;
    }
    .blog_list_item_two a img{
        width: 100%;
    }
    .header_social_icon{
        display: none;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu .nav-item{
        width: calc(100% / 2);
    }
    .software_promo_area .round_shape{
        width: auto;
        height: 600px;
        max-width:100%;
        min-height: 100%;
        margin-top: 50px;
    }
    .software_promo_area .round_shape .r_shape.r_shape_one{
        height: 580px;
        width: 580px;
    }
    .payment_content_two .payment_img img{
        max-width: 100%;
    }
    .payment_content_two .payment_img .payment_icon.icon_one,.payment_content_two .payment_img .payment_icon.icon_two,.payment_content_two .payment_img .payment_icon.icon_three,.payment_content_two .payment_img .payment_icon.icon_four,.payment_content_two .payment_img .payment_icon.icon_five,.payment_content_two .payment_img .payment_icon.icon_six{
        max-width: 48%;
    }
    .payment_content_two .payment_img .payment_icon.icon_five {
        right: -17px;
        top: 19px;
    }
    .payment_content_two .payment_img .payment_icon.icon_two {
        left: 20px;
        top: 15%;
    }
    .payment_content_two .payment_img .payment_icon.icon_six {
        top: 116px;
    }
    .payment_content_two .action_btn{
        display: inline-block !important;
        position: relative;
        z-index: 3;
    }
    .payment_content_two h2 {
        font-size: 40px;
        line-height: 55px;
    }
    .payment_banner_area_two{
        padding-top: 135px;
    }
    .payment_features_content_two h2 br{
        display: none;
    }
    .payment_features_img img{
        max-width: 100%;
    }
    .payment_features_img:before{
        top: -30px;
        left: -10px;
        right: 0;
    }
    .payment_subscribe_info .subscribe-form{
        display: block;
        text-align: center;
    }
    .payment_subscribe_info .subscribe-form .form-control{
        width: 100%;
        margin-bottom: 15px;
    }
    .payment_footer_area{
        padding-bottom: 75px;
    }
    .seo_fact_info{
        max-width: 320px;
        margin: 0 auto;
    }
    .seo_fact_info .seo_fact_item:nth-child(even){
        margin-top: 135px;
    }
    .seo_subscribe_form .input-group{
        margin-bottom: 20px;
    }
    .new_footer_area .footer_bottom p{
        text-align: center;
        margin-bottom: 0 !important;
    }
    .seo_features_img .round_circle,.seo_features_img.seo_features_img_two .round_circle{
        width: 340px;
        height: 340px;
        top: -40px;
    }
    .seo_features_img.seo_features_img_two .round_circle.two{
        top: -50px;
        right: 0px;
    }
    .seo_features_content .seo_btn{
        margin-top: 20px;
    }
    .seo_features_img img{
        margin-right: 0;
    }
    .seo_features_img .round_circle.two{
        left: 0;
    }
    .seo_subscribe_form{
        margin-left: 0;
        margin-right: 0;
    }
    .seo_sec_title.mb_70{
        margin-bottom: 40px;
    }
    .seo_service_item{
        padding-left: 15px;
        padding-right: 15px;
    }
    .seo_partner_logo_area .seo_sec_title h2{
        font-size: 28px;
    }
    .seo_call_action_text h2 {
        font-size: 27px;
        line-height: 41px;
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu .nav-item{
        width: 50%;
    }
    .hosting_tab .nav-item{
        width: 50%;
    }
    .hosting_tab{
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .h_blog_item{
        margin-bottom: 30px;
    }
    .hosting_title h2 {
        font-size: 31px;
        line-height: 40px;
    }
    .h_map ul li:nth-child(1) {
        left: 26px;
    }
    .h_map ul li:nth-child(2) {
        left: 55px;
    }
    .h_map ul li:nth-child(7) {
        left: 24%;
    }
    .h_map ul li:nth-child(4) {
        top: 279px;
    }
    .h_map ul li:nth-child(8){
        left: 72%;
    }
    .h_map ul li:nth-child(6) {
        top: 83%;
        left: 128px;
    }
    .pos_banner_text h2{
        font-size: 40px;
        line-height: 52px;
    }
    .pos_banner_text .action_btn{
        display: block !important;
    }
    .animation_inner li{
        max-width: 22%;
    }
    .animation_inner li img{
        max-width: 100%;
    }
    .animation_inner li:nth-child(8) {
        top: 31%;
        left: 5px;
    }
    .animation_inner li:nth-child(3) {
        top: 130px;
        left: 109px;
    }
    .animation_inner li:nth-child(14) {
        left: 114px;
    }
    .animation_inner li:nth-child(16) {
        bottom: -25px;
        left: 165px;
    }
    .animation_inner li:nth-child(17) {
        left: 228px;
        bottom: 53px;
    }
    .animation_inner li:nth-child(18) {
        bottom: -87px;
        left: 202px;
    }
    .animation_inner li:nth-child(19) {
        bottom: 137px;
        left: 219px;
    }
    .animation_inner li:nth-child(11) {
        top: 44px;
        left: 205px;
    }
    .animation_inner li:nth-child(10) {
        left: 45px;
        top: 215px;
    }
    .animation_inner li:nth-child(9) {
        left: 150px;
        top: 203px;
    }
    .animation_inner li:nth-child(7) {
        left: 224px;
    }
    .animation_inner li:nth-child(6) {
        top: 106px;
        left: 220px;
    }
    .animation_inner li:nth-child(5) {
        top: 187px;
        left: 0;
    }
    .animation_inner li:nth-child(4) {
        left: 0;
        top: auto;
        bottom: -91px;
    }
    .erp_testimonial_item .content p{
        font-size: 16px;
        line-height: 30px;
    }
    .erp_testimonial_item .content{
        padding: 50px 25px
    }
    .erp_testimonial_item .media{
        padding-left: 0;
    }
    .erp_testimonial_item .media .media-body h5,.erp_testimonial_item .media .media-body h5 span,.erp_testimonial_item .media .media-body p{
        font-size: 15px;
    }
    .erp_testimonial_item .media img{
        margin-right: 12px;
    }
    .flex-row-reverse .erp_content_two{
        padding-right: 0;
    }
    .flex-row-reverse .erp_features_img_two .img_icon{
        left: -7px;
        right: auto;
    }

  .container.custom_container {
    padding: 0px 30px;
  }

  .header_area.navbar_fixed .navbar-brand img {
    width: 170px;
  }

  .menu_one, .header_area.navbar_fixed .menu_one, .menu_two, .menu_six, .menu_seven {
    padding: 5px 0px;
  }

  .agency_testimonial_info .owl-prev, .agency_testimonial_info .owl-next {
    background-color: #0000ff;
    z-index: 2;
    i {
        color: #fff;
    }
  }

  .saas_subscribe_area h4 {
    font-size: 1.5rem;
  }

.saas_subscribe_area .saas_action_content .gr_btn {
    padding: 15px 0px;
  }

  .job_info {
      padding: 20px;
  }

  .double_item .service_details ul {
      text-align: unset;
      margin-bottom: unset;
  }

  .center_list {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-flow: wrap;
            flex-flow: wrap;
    text-align: center;
  }


  .service_details {
      // display: -webkit-flex;
      // display: flex;
      // -webkit-justify-content: center;
      //         justify-content: center;
      //         -webkit-flex-direction: column;
      //                 flex-direction: column;
      //                     -webkit-align-items: center;
      //                             align-items: center;

          display: inline-block;


  }

  .service_details ul {
    margin-bottom: 10px;
}

  .service_details h2 {
      font-size: 21px;
    }

    .service_details p {
    font-size: 16px; }

    .nav ul li a {
      font-size: 1.2rem;
      line-height: 2.5;
    }
    .resposive_menu_body.nav {
        margin-top: unset;
    }

    .responsive_menu_info {
      padding: 1rem;
    }

    #burger {
      margin-top: 10px;
    }

    .header_area {
      &.navbar_fixed {

          .hamburger-box {
              margin-top: .5rem !important;
          }
      }
    }

    .explore_app {
      padding: 15px 0 0;
      font-size: 13px;
    }

    .agency_content h2 {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 5px !important;
    }

    .agency_banner_area {
      padding-top: 115px;
    }

    .agency_content p {
      font-size: 16px;
    }

    .action_btn.d-flex.align-items-center.mt_60 {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .req_wrapper {
      padding: 25px 0;
    }

    .req_wrapper h5 {
        font-size: 1.4rem;
    }

    .service_details.two_lists ul {
      text-align: left;
      width: 100%;
      float: unset;
    }

    .testimonial_title {
      text-transform: uppercase;
      font-size: 1.1rem;
    }

    .swiper-slide img {
      width: 130px;
    }

    .company_widget img, .protype_img {
      width: 200px;
    }

    .p__item strong {
      &:before {
        border-bottom: 3px solid;
        top: 18px;
      }
    }

    .agency_featured_item .agency_featured_content h3 {
        font: 600 18px/36px "Poppins", sans-serif;
    }

    .agency_banner_btn i {
      top: 3px;
      position: relative;
  }


}
