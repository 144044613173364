/*------------------------------------------------------------------
[Responsive Stylesheet]
WAPP DESIGN
Template:	Hotelizer | Your hotel organizer
-------------------------------------------------------------------*/

/*-----------------------------------------------------------------

[Responsive Content]

1. _media_2000px
2. _media_1650px
3. _media_1550px
4. _media_1450px
5. _media_1350px
6. _media_1200px
7. _media_991px
8. _media_768px
9. _media_650px
10. _media_576px
11. _media_480px
12. _media_450px
13. _media_420px
14. _media_400px

*/

@import "responsive_scss/_media_2000px";
@import "responsive_scss/_media_1650px";
@import "responsive_scss/_media_1550px";
@import "responsive_scss/_media_1450px";
@import "responsive_scss/_media_1350px";
@import "responsive_scss/_media_1200px";
@import "responsive_scss/_media_991px";
@import "responsive_scss/_media_768px";
@import "responsive_scss/_media_650px";
@import "responsive_scss/_media_576px";
@import "responsive_scss/_media_480px";
@import "responsive_scss/_media_450px";
@import "responsive_scss/_media_420px";
@import "responsive_scss/_media_400px";
