
@media (max-width:1350px){
    .payment_banner_content {
        max-width: 556px;
    }
    .animation_img_two{
        bottom: 120px;
    }
    .digital_video_slider {
        width: 55%;
    }
    .erp_banner_area_two .animation_img img{
        max-width: 100%;
    }
    .erp_features_img_two img{
        max-width: 100%;
    }
    .flex-row-reverse .erp_content_two {
        padding-right: 20px;
    }
    .erp_content_two{
        padding-left: 30px;
    }
    .erp_testimonial_info .owl-nav button.owl-prev{
        left: 0;
    }
    .erp_testimonial_info .owl-nav button.owl-next{
        right: 0;
    }
    .erp_testimonial_info .owl-nav button.owl-prev,.erp_testimonial_info .owl-nav button.owl-next{
        opacity: 0;
    }
    .erp_testimonial_info:hover .owl-nav button.owl-prev,.erp_testimonial_info:hover .owl-nav button.owl-next{
        opacity: 1;
    }

    .agency_content h2 {
      margin-bottom: unset;
    }

    .agency_content h2 {
      font-size: 36px;
      line-height: 44px;
    }

    .agency_banner_area .protype_img {
        margin-top: -1rem;
    }

    .action_btn.d-flex.align-items-center.mt_60 {
      margin: 35px 0 0;
    }

    .agency_banner_btn {
        padding: 15px 20px;
    }

}
