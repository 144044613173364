

@media (max-width:768px){
    .sec_title br,.prototype_service_info h2 br,.hosting_title h2 br{
        display: none;
    }
    .footer_bottom p{
        text-align: center;
        margin-bottom: 12px !important;
    }
    .footer_bottom .f_social_icon_two{
        text-align: right !important;
        margin-bottom: 12px;
    }
    .footer_bottom .f_menu{
        text-align: center !important;
    }
    .banner_top h2{
        font-size: 32px;
    }
    .seo_banner_content h2{
        line-height: 48px;
    }
    .l_height60{
        line-height: 45px;
    }
    .footer_top_two .f_width_24, .f_width_12, .f_width_20{
        width: 100%;
    }
    .footer_area_two .footer_bottom p{
        text-align: left;
        margin-bottom: 0 !important;
    }
    .partner_logo .p_logo_item {
        width: calc(100% / 3);
    }
    .prototype_featured_area h2 br{
        display: none;
    }
    .service_carousel{
        padding-left: 0;
    }
     .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev{
        opacity: 0;
    }
    .service_carousel:hover .owl-nav .owl-next,.service_carousel:hover .owl-nav .owl-prev{
        opacity: 1;
    }
    .feedback_slider_two .owl-nav .owl-next,.feedback_slider_two .owl-nav .owl-prev{
        opacity: 1;
    }
    .prototype_service_area{
        padding-bottom: 70px;
    }
    .prototype_service_info .mb_90{
        margin-bottom: 70px;
    }
    .about_img .about_img_slider .about_item .about_text h5{
        font-size: 15px;
    }
    .agency_featured_item .agency_featured_img{
        text-align: center !important;
    }
    .agency_featured_item .agency_featured_content{
        padding-left: 0;
        margin-top: 50px;
    }
    .action_area_three:before{
        background-size: contain;
    }
    .action_area_three .action_content{
        margin: 0px;
    }
    .mb_90{
        margin-bottom: 50px;
    }
    // .agency_service_area{
    //     padding: 70px 0px;
    // }
    .agency_featured_area {
        padding-top: 120px;
    }
    .agency_testimonial_area,.software_featured_area{
        padding-top: 70px
    }
    .agency_testimonial_info .testimonial_slider{
        padding: 50px 20px;
    }
    .sec_pad,.prototype_service_info{
        padding: 70px 0px;
    }
    .sec_title.mb_70{
        margin-bottom: 50px
    }
    .pricing_area {
        padding-bottom: 10px;
    }
    .design_img_two{
        margin-top: -50px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .software_service_tab .nav-item{
        display: inline-block;
        margin-bottom: 20px;
    }
    .software_service_tab_content{
        margin-top: 60px;
    }
    .developer_product_area{
        padding: 70px 0px;
    }
    .partner_logo_area_two .subscribe_form_info{
        padding: 100px 20px;
    }
    .partner_logo_area_two .subscribe_form_info h2{
        line-height: 42px;
    }
    .saas_signup_form .input-group{
        margin-bottom: 25px;
    }
    .saas_signup_form .saas_banner_btn{
        margin-top: 40px;

    }
    .fun_fact_content .fact_item.pl_100{
        padding-left: 30px;
    }
    .fun_fact_content,.cart_box{
        margin-top: 50px;
    }
    .footer_area_six.sec_pad{
        padding-bottom: 30px;
    }
    .feedback_area .feedback_slider .item{
        padding: 0px;
    }
    .feedback_slider .feedback_item{
        padding-left: 20px;
        padding-right: 20px;
    }
    .app_featured_area,.agency_featured_area_two{
        padding-bottom: 80px;
    }
    .app_service_area{
        padding-top: 80px;
    }
    .fact_author_img.fact_author_img_two{
        text-align: center !important;
    }
    .features_info.feature_info_two .agency_featured_img{
        margin-bottom: 0px;
    }
    .partner_logo_area_five{
        padding-top: 110px;
        padding-bottom: 50px;
    }
    .blog_area .shop_page_number{
        text-align: center !important;
    }
    .blog_single .blockquote{
        padding-left: 15px;
    }
    .post_share a i{
        padding-right: 4px;
    }
    .post_share a + a {
        margin-left: 5px;
    }
    .post_tag .post-nam {
        padding-right: 0;
    }
    .post_tag a{
        margin-left: 3px;
    }
    .portfolio_filter .work_portfolio_item + .work_portfolio_item {
        margin-left: 35px;
    }
    .price_content .price_item{
        padding-left: 15px;
        padding-right: 15px;
    }
    .process_area .agency_featured_item{
        margin-top: 50px;
    }
    .single_product_item .single_pr_details{
        padding-top: 30px;
    }
    .sign_info {
        padding: 50px 30px;
    }
    .s_promo_info .promo_item{
        opacity: 1;
        left: 0;
        top: 75%;
    }
    .banner_top {
        padding-top: 125px;
    }
    .svg_intro_bottom{
        width: 130%;
    }
    .payment_banner_content{
        max-width: 100%;
    }
    .payment_banner_content h1 {
        line-height: 50px;
        font-size: 32px;
    }
    .payment_banner_content .action_btn{
        margin-top: 45px;
    }
    .slider_content{
        padding-top: 150px;
    }
    .saas_features_area_two {
        padding-top: 165px;
    }
    .section_container .intro_content{
        position: relative;
        padding-top: 150px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .section_container .intro_content h1{
        font-size: 44px;
    }
    .saas_service_img img{
        max-width: 100%;
    }
    .about_img .about_img_slider .about_item .about_text .br{
        margin-bottom: 15px;
    }
    .s_service_section {
        margin-top: 120px;
    }
    .mobile_img .women_img {
        top: 50px;
        right: 200px;
        max-width: 40%;
    }
    .design_tab_area .tab-content .tab_img{
        margin-top: 20px;
    }
    .saas_featured_info .f_img_two:before{
        top: -20px;
    }
    .saas_featured_info .saas_featured_item{
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    .payment_testimonial_area .testimonial_img {
        margin-bottom: -24px;
    }
    .payment_testimonial_info .testimonial_content .icon{
        left: 0;
    }
    .payment_testimonial_info .testimonial_content{
        padding-left: 115px;
    }
    .partner_logo_area_two {
        padding-top: 70px;
    }
    .payment_action_content p br{
        display: none;
    }
    .s_features_item .s_features_content .learn_btn{
        margin-top: 05px;
    }
    .f_widget .mb_40{
        margin-bottom: 16px;
    }
    .company_widget .mt_30{
        margin-top: 20px;
    }
    .feature_info .f_content h2{
        line-height: 33px;
        margin-bottom: 15px;
    }
    .breadcrumb_area{
        padding: 140px 0px 140px;
    }
    .breadcrumb_content p br{
        display: none;
    }
    .job_listing .listing_tab .list_item{
        padding: 30px;
    }
    .job_listing .listing_tab .list_item .joblisting_text{
        display: block;
    }
    .job_listing .listing_tab .list_item .joblisting_text .job_list_table,.job_listing .listing_tab .list_item .joblisting_text .jobsearch-table-cell{
        display: block;
        width: 100%;
        overflow: hidden;
    }
    .job_listing .listing_tab .list_item .joblisting_text{
        padding: 0px;
        border: 0px;
    }
    .job_listing .listing_tab .list_item figure{
        margin-bottom: 20px;
    }
    .job_listing .listing_tab .list_item figure,.job_listing .listing_tab .list_item figure a, .job_listing .listing_tab .list_item figure img,.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist{
        float: none;
    }
    .job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist{
        display: -webkit-flex;
        display: flex;
        padding-top: 25px;
    }
    .job_listing {
        margin-top: 70px;
    }
    .checkout_content .checkout_title{
        margin-top: 40px;
    }
    .checkout_area .cart_total_box{
        margin-top: 50px;
    }
    .checkout_content textarea{
        padding-left: 20px;
    }
    .sign_info{
        background: #fff;
    }
    .error_contain h1 {
        font-size: 20vw;
        line-height: 22vw;
    }
    .shopping_cart_area .cart_title{
        display: none;
    }
    .shopping_cart_area .cart_table tr{
        display: block;
        float: none;
        width: 100%;
        margin-bottom: 40px;
        background: #f5f5f5;
        padding: 0px 15px;
    }
    .shopping_cart_area .cart_table tr td{
        display: block;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        padding-bottom: 20px;
        padding-top: 20px !important;
        margin-bottom: 0px;
        border-bottom: 1px solid #ededed;
    }
    .shopping_cart_area .cart_table tr td:last-child{
        border-bottom: 0px;
    }
    .shopping_cart_area .cart_table tr td:before{
        content: attr(data-title) ": ";
        font-weight: 600;
        float: left;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    .shopping_cart_area .cart_table{
        margin: 0px;
        padding-top: 0;
    }
    .shopping_cart_area .cart_table .total, .shopping_cart_area .cart_table .quantity,.shopping_cart_area .cart_table .del-item{
        float: right;
        width: auto;
    }
    .shopping_cart_area .cart_table .del-item .total{
        float: none;
    }
    .shopping_cart_area .cart_table .product .media{
        display: inline-block;
        width: auto;
        float: right;
    }
    .shopping_cart_area .cart_table .product .media .media-left{
        margin-left: auto;
        margin-bottom: 20px;
    }
    .shopping_cart_area .cart_table .del-item a{
        display: -webkit-inline-flex;
        display: inline-flex;
    }
    .app_img .text_bg.three {
        right: -30%;
        bottom: 48px;
    }
    .app_img .dot_two {
        right: 157px;
        top: 76%;
    }
    .company_banner_area .company_banner_content h2{
        font-size: 40px;
        line-height: 52px;
    }
    .company_banner_area .company_banner_content h2 br{
        display: none;
    }
    .startup_tab .nav-item{
        width: calc(100% / 3);
    }
    .startup_tab_img .phone_img img {
        max-width: 58%;
    }
    .progress_bar_area {
        padding-top: 70px;
    }
    .progress_bar_area .br_bottom{
        margin-top: 55px;
    }
    .stratup_app_screen .phone {
        max-width: 80%;
        bottom: -222px;
        left: -32px;
    }
    .digital_banner_area{
        display: block;
        min-height: auto;
        padding: 140px 0px 100px;
    }
    .digital_video_slider {
        width: 100%;
        position: relative;
        top: auto;
        bottom: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        padding: 50px 15px 0px;
    }
    .digital_video_slider .owl-stage{
        padding-left: 100px !important;
    }
    .digital_content{
        padding-right: 0;
    }
    .saas_banner_area_three .slider_item .slidet_content_two h2{
        font-size: 40px;
    }
    .saas_banner_area_three .slider_item .slidet_content_two h2 br{
        display: none;
    }
    .saas_banner_area_three .slider_item .image_mockup .watch,.saas_banner_area_three .slider_item .image_mockup .phone{
        display: none;
    }
    .saas_banner_area_three .slider_item .image_mockup{
        width: 90%;
        padding: 0px;
        position: relative;
        margin-top: 50px;
        bottom: 0;
    }
    .saas_banner_area_three .slider_item{
        height: auto;
        padding-bottom: 50px;
        min-height: auto;
    }
    .saas_banner_area_three .slider_item .image_mockup .laptop {
        max-width: 100%;
    }
    .saas_banner_area_three .owl-stage{
        padding-bottom: 0;
    }
    .service_details_area .pr_70{
        padding-right: 15px;
    }
    .price_info_two .price_head .p_head{
        display: none;
    }
    .price_info_two .price_head .p_head.time{
        display: block;
        width: 100%;
    }
    .price_info_two .pr_list{
        display: block;
        width: 100%;
    }
    .price_info_two .price_item{
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
        padding: 0px;
    }
    .price_info_two .price_item:first-child{
        text-align: center;
        padding: 5px 0px;
    }
    .price_info_two .price_item:first-child h5{
        width: initial;
        border: 0px;
        float: none;
    }
    .price_info_two .price_item:before{
        content: attr(data-title);
        position: absolute;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        font-weight: 500;
    }
    .price_info_two .price_item h5{
        border-left: 1px dashed #ededed;
        display: block;
        float: right;
        font-size: 1.1rem;
        height: 100%;
        line-height: 3.1rem;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
    }
    .price_info_two .pr_list:last-child{
        display: none;
    }
    .blog_breadcrumb_area .breadcrumb_content_two h1 br{
        display: none;
    }
    .blog_breadcrumb_area .breadcrumb_content_two h1{
        font-size: 7vw;
        font-weight: 700;
        line-height: 1.5em;
    }
    .header_social_icon li {
        margin: 0px 0px 0px 13px;
    }
    .software_promo_area .round_shape {
        width: 600px;
        height: 600px;
        max-width: 100%;
        min-height: 100%;
        margin-top: 100px;
    }
    .software_promo_area .round_shape .r_shape.r_shape_two{
        width: 400px;
        height: 400px;
    }
    .software_promo_area .round_shape .r_shape.r_shape_three{
        width: 230px;
        height: 230px;
    }
    .payment_features_area_three {
        padding: 80px 0px;
    }
    .payment_features_area_three .col-lg-5 img{
        max-width: 100%;
    }
    .payment_features_content_two .item{
        margin-top: 0;
    }
    .payment_features_content_two p br{
        display: none;
    }
    .payment_logo_area,.payment_priceing_area{
        padding: 80px 0px;
    }
    .payment_content_two .payment_img img{
        max-width: 100%;
    }
    .feedback_area_three {
        height: auto;
    }
    .seo_subscribe_form .input-group{
        -webkit-justify-content: center;
                justify-content: center;
    }
    .seo_subscribe_form .input-group .check-btn{
        margin-top: 10px;
    }
    .new_footer_area .footer_bottom p{
        text-align: left;
        font-size: 14px;
    }
/*    new css*/
    .hosting_banner_area{
        min-height: 100%;
        height: auto;
        text-align: center;
        padding: 130px 0px 80px;
    }
    .hosting_banner_area .hosting_content{
        margin-bottom: 40px;
    }
    .domain_form_inner input{
        padding-left: 5px;
    }
    .h_map_area .hosting_title br{
        display: none;
    }
    .h_action_promo_area .text-right,.h_action_promo_area{
        text-align: center !important;
    }
    .h_action_promo_area .hosting_btn{
        margin-top: 25px;
    }
    .h_action_content{
        margin-top: 30px;
    }
    .pos_subscribe .col-sm-12 .form-group{
        text-align: center;
    }
    .pos_subscribe .form-group{
        margin-top: 15px;
    }
    .pos_subscribe .btn_pos {
        padding: 17px 56px;
    }
    .erp_banner_area_two .section_container .intro{
        margin-left: 0;
        margin-right: 0;
    }
    .erp_action_area .erp_content{
        margin-top: 30px;
    }
    .erp_analytics_item p{
        font-size: 22px;
    }
    .erp_analytics_item{
        margin-bottom: 25px;
    }
    .erp_menu .er_btn{
        margin-left: 30px;
    }
    .pos_developer_product_area .tab_img_info{
        overflow: hidden;
    }

    .agency_featured_area h2 {
      font-size: 1.7rem;
    }
    .agency_featured_area h2 strong {
      font-size: 1.7rem;
    }

    .footer_top .f_widget {
      text-align: center;
    }

    .footer_bottom {
      text-align: center;
  }

  #wapp {
    display: inline;
    float: unset;
}

.breadcrumb_area:before {
    width: 100%;
    height: 170%;
    margin-top: -5rem;
  }

  .contact_info_area {
    .pr-0 {
        padding-right: 15px!important;
    }
  }

  .footer_top {
    padding-bottom: 10px;
  }

  .footer_top {
    padding: 75px 0px 100px;
  }

  .double_item.hs_kpng {
    ul {
      li {
        font-size: 14px;
      }
    }
  }

  .service_details_header p {
    font-size: 18px;
  }

  .service_details_item {
    padding: 30px;
  }

  .service_details_header p {
    padding: 0rem 20px;
}

.service_details ul li {
    font-size: 15px;
    padding-left: 20px;
}



.col-lg-12 .center_list .mb-30 {
  margin-bottom: 5px;
}

.service_details_area .service_details_img {
  margin-bottom: 20px;
  margin-top: 30px;
}

.svg_animation .path2 {
    -webkit-animation: unset;
    animation: unset;
}

.swiper-container.swiper-testimonials {
  width: 100%;
  height: 150px;
}

.saas_subscribe_area h4 {
  font-size: 1.6rem;
}

.req_wrapper h5 {
  font-size: 2rem;
}

.f_widget.about-widget .f_list li {
    display: block;
    padding-right: 0;
}

.f_widget{

    &.about-widget{
        .f_list{
            li{
                a {
                    font: 300 16px/16px "Poppins", sans-serif;;
                    color: #677294;
                    position: relative;
                    transition: color 0.2s linear;
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    text-transform: uppercase;

                  }
                }
              }
            }
          }

          .agency_banner_btn i {
              top: 0px;
              position: relative;
          }

}
